import React, { useState, useRef, useEffect, useCallback } from "react";
import { OpenStreetMapProvider, GeoSearchControl } from "leaflet-geosearch";
import "leaflet-geosearch/dist/geosearch.css";
import icon from "../../../assets/images/marker-icon.png";
import { MapContainer, TileLayer, LayersControl, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
import "@geoman-io/leaflet-geoman-free";
import MarkerClusterGroup from "react-leaflet-cluster";
import NewsMapSidebar from "../sidebars/NewsMapSidebar";
import FiltersSidebar from "../sidebars/FiltersSidebar";
import CountryLabel from "./TopCountryLabel";
import { BookmarkProvider } from "../../side-panel/sidepanel-components/bookmarks/BookmarkContext";
import {
  handleMarkerClick,
  getPosts,
  getFullPosts,
  getAddress,
  fetchCategories,
  shouldFetch,
} from "../UtilesMap/MapUtiles";
import { debounce } from "lodash";
import { MyMarker } from "./MarkerAndPopup";
import Popup from "../../post/popup";

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png",
  iconUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
});

const MapComponent = () => {
  const mapRef = useRef(null);

  const [sidebarOpen, setSidebarOpen] = useState(false);
  // the current items are the current posts
  const [currentItems, setCurrentItems] = useState([]);
  // these are filters
  const [countryCode, setCountryCode] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  //categories
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  //pagination
  const [defaultPosts, setDefaultPosts] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [CountryLoading, setCountryLoading] = useState(false);
  const [page, setPage] = useState(1);

  // Default values
  const center = L.latLng(32.5, 35.5);
  const bufferPercentage = 0.6;
  const postsCount = 500;
  const limit = 10;

  const prevCenterRef = useRef(null);
  const prevRadiusRef = useRef(null);
  const prevStartDateRef = useRef(null);
  const prevEndDateRef = useRef(null);
  const prevCountryCodeRef = useRef(null);
  // I needed 2 refs for the country code because it didnt work with one
  const prevCountryCodeMapRef = useRef(countryCode);
  const prevCategoryRef = useRef(selectedCategories);

  const [currentsender, setCurrentSender] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [currentId, setCurrentPost] = useState("");

  const handleOpenPostPopup = (sender_username, post_id) => {
    setCurrentSender(sender_username);
    setCurrentPost(post_id);
    setIsOpen(true);
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  // handle category change
  const handleCategoryChange = (selectedOptions) => {
    setSelectedCategories(selectedOptions || []);
  };

  // get post formatedCategories
  useEffect(() => {
    fetchCategories(setCategories);
  }, []);

  // get full posts for sidebar
  useEffect(() => {
    const fetchData = async () => {
      const selectedCategoryValues = selectedCategories.map(
        (category) => category.value
      );

      const data = await getFullPosts(
        limit,
        page,
        selectedCategoryValues,
        countryCode,
        startDate,
        endDate
      );

      // Check if any filters are set
      const filtersSet =
        selectedCategoryValues.length > 0 ||
        startDate ||
        endDate ||
        countryCode;

      // Determine if the country code has changed
      const countryChanged = prevCountryCodeRef.current !== countryCode;
      const isDefaultPostScenario = !countryCode && !filtersSet; // This checks if no filters including country code are set

      if (isDefaultPostScenario) {
        setFilteredPosts([]); // Clear filtered posts if no filters are set
        setDefaultPosts((prevPosts) => [...prevPosts, ...data]);
      } else if (filtersSet) {
        if (countryChanged) {
          setFilteredPosts(data); // Replace all posts if country code changes
          setPage(1); // Reset page to 1
        } else {
          setFilteredPosts((prevPosts) => [...prevPosts, ...data]); // Append data if not changed
        }
      }

      // Update the ref to the current country code after processing
      prevCountryCodeRef.current = countryCode;
    };

    fetchData();
  }, [startDate, endDate, countryCode, selectedCategories, page, limit]);

  // get map posts
  const fetchData = useCallback(
    async (center, radius) => {
      const currentCenter = L.latLng(center);
      const currentRadius = radius;

      // Check if conditions have significantly changed
      const dateChanged =
        prevStartDateRef.current !== startDate ||
        prevEndDateRef.current !== endDate;
      const centerChanged =
        !prevCenterRef.current ||
        !prevCenterRef.current.equals(currentCenter) ||
        prevRadiusRef.current !== currentRadius;

      const countryChanged = prevCountryCodeMapRef.current !== countryCode;
      const categoryChanged = prevCategoryRef.current !== selectedCategories;

      if (
        !dateChanged &&
        !centerChanged &&
        !countryChanged &&
        !categoryChanged
      ) {
        return; // Exit if no significant changes
      }

      // Update refs with the new values
      prevCenterRef.current = currentCenter;
      prevRadiusRef.current = currentRadius;
      prevStartDateRef.current = startDate;
      prevEndDateRef.current = endDate;
      prevCountryCodeMapRef.current = countryCode;
      prevCategoryRef.current = selectedCategories;

      const selectedCategoryValues = selectedCategories.map(
        (category) => category.value
      );

      const newPosts = await getPosts(
        postsCount,
        selectedCategoryValues,
        countryCode,
        currentCenter,
        currentRadius,
        startDate,
        endDate
      );

      setCurrentItems((prevItems) => {
        if (dateChanged || countryChanged || categoryChanged) {
          // If date changed, replace the items
          return newPosts;
        } else {
          const existingIds = new Set(prevItems.map((post) => post.pid));
          const newIds = new Set(newPosts.map((post) => post.pid));

          // Filter existing posts to keep only those within the max distance
          const maxDistance = currentRadius * 2; // Define a max distance threshold
          const relevantExistingPosts = prevItems.filter((post) => {
            const postPosition = L.latLng([post.lat, post.lng]);
            return currentCenter.distanceTo(postPosition) <= maxDistance;
          });

          // Check if there's actually a change in the dataset
          if (
            relevantExistingPosts.length === prevItems.length &&
            newIds.size === existingIds.size &&
            [...existingIds].every((id) => newIds.has(id))
          ) {
            return prevItems; // Return existing items if no change
          }

          // Merge new posts, avoiding duplicates
          return [
            ...relevantExistingPosts,
            ...newPosts.filter((post) => !existingIds.has(post.pid)),
          ];
        }
      });
    },
    [selectedCategories, postsCount, countryCode, startDate, endDate]
  );

  const debouncedFetchData = useCallback(
    debounce((center, radius) => {
      fetchData(center, radius);
    }, 500),
    [fetchData]
  );

  const UpdateCenterComponent = () => {
    const map = useMap();

    const prevCenter = useRef(map.getCenter());
    const prevRadius = useRef(0);

    useEffect(() => {
      // let circle = null;

      const updateCenterAndCircle = () => {
        const newCenter = map.getCenter();
        const bounds = map.getBounds();
        const northEast = bounds.getNorthEast();
        const newRadius = map.distance(newCenter, northEast) * bufferPercentage;
        const currentZoom = map.getZoom();

        // Only fetch data if there's a significant change in center or radius
        if (
          shouldFetch(
            prevCenter.current,
            prevRadius.current,
            newCenter,
            newRadius,
            currentZoom
          )
        ) {
          debouncedFetchData(newCenter, newRadius);
          prevCenter.current = newCenter;
          prevRadius.current = newRadius;
        }

        // if (!circle) {
        //   circle = L.circle(newCenter, {
        //     radius: newRadius,
        //     color: "blue",
        //     fillColor: "#30f",
        //     fillOpacity: 0.3,
        //   }).addTo(map);
        // } else {
        //   circle.setLatLng(newCenter);
        //   circle.setRadius(newRadius);
        // }
      };

      updateCenterAndCircle();
      map.on("moveend", updateCenterAndCircle);

      return () => {
        map.off("moveend", updateCenterAndCircle);
        debouncedFetchData.cancel();
        // if (circle) circle.remove();
      };
    }, [map]);

    return null;
  };

  // leaflet map effect ; clicks , mousedown , mouseup , etc
  const MapEffect = () => {
    const map = useMap();

    useEffect(() => {
      map.pm.addControls({
        position: "topleft",
        drawMarker: true,
        drawPolyline: true,
        drawRectangle: true,
        drawPolygon: true,
        drawCircle: true,
        editMode: true,
        dragMode: true,
        cutPolygon: true,
        removalMode: true,
      });

      const handleMapDoubleClick = async (e) => {
        const lat = e.latlng.lat;
        const lon = e.latlng.lng;
        getAddress(lat, lon, setCountryCode, setCountryLoading);
      };

      map.on("dblclick", handleMapDoubleClick);

      return () => {
        map.off("dblclick", handleMapDoubleClick);
      };
    }, [map]);
    return null;
  };

  // leaflet search bar control
  const LeafletgeoSearch = () => {
    const map = useMap();
    useEffect(() => {
      const provider = new OpenStreetMapProvider();

      const searchControl = new GeoSearchControl({
        provider,
        marker: {
          icon: L.icon({
            iconUrl: icon,
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            shadowUrl:
              "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
          }),
        },
      });

      map.addControl(searchControl);

      return () => map.removeControl(searchControl);
    }, [map]);

    return null;
  };

  return (
    <div className="map-rotate-container">
      <CountryLabel
        country={countryCode}
        loading={CountryLoading}
        onClear={() => {
          setCountryCode(null);
        }}
      />
      {isOpen && (
        <Popup
          senderusername={currentsender}
          post_id={currentId}
          onClose={() => setIsOpen(false)}
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: "1000", // Adjust zIndex as needed
          }}
        />
      )}
      <MapContainer
        center={center}
        zoom={8}
        style={{ height: "100vh", width: "100%" }}
        ref={mapRef}
        maxZoom={18}
        minZoom={3}
      >
        <LayersControl position="topleft">
          <LayersControl.BaseLayer name="Street Map" checked>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              opacity={0.6}
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Satellite Imagery">
            <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />
          </LayersControl.BaseLayer>
        </LayersControl>
        <MarkerClusterGroup disableClusteringAtZoom={10}>
          {currentItems.map((item, index) => (
            <MyMarker
              item={item}
              key={`${item.pid}-${index}`}
              index={index}
              handleMarkerClick={() =>
                handleMarkerClick(
                  item.pid,
                  toggleSidebar,
                  filteredPosts.length === 0,
                  setDefaultPosts,
                  setFilteredPosts
                )
              }
              categories={categories}
            />
          ))}
        </MarkerClusterGroup>

        <LeafletgeoSearch />
        <MapEffect />
        <UpdateCenterComponent />
      </MapContainer>
      <BookmarkProvider>
        <NewsMapSidebar
          news={filteredPosts.length > 0 ? filteredPosts : defaultPosts}
          country={countryCode}
          openSidebar={sidebarOpen}
          setPage={setPage}
          handleOpenPostPopup={handleOpenPostPopup}
        />
      </BookmarkProvider>
      <FiltersSidebar
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        categories={categories}
        handleCategoryChange={handleCategoryChange}
      />{" "}
    </div>
  );
};

export default MapComponent;
