import AreaTable from "../components/dashboard/areaTable/Organizations/AreaTable";

const Orgs = () => {
  return (
    <div className="content-area">
      <AreaTable />
    </div>
  );
};
export default Orgs;
