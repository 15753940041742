import React, { useState } from "react";
import styled from "styled-components";
import { useTheme } from "../themes/ThemeContext";
import { postAuth } from "../../globalUtils";
import Spinner from "../../Spinner";
import Select from "react-select";
import { DatePicker } from "../DraggableColumn";
import { toast } from "sonner";

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
`;

export const Modal = styled.div`
  background-color: ${({ theme }) => (theme === "dark" ? "#333" : "white")};
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 500px;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  box-shadow: 0 4px 4px -3px rgba(0, 0, 0, 0.1); /* Bottom-focused shadow */
`;

const ModalFooter = styled.div`
  position: sticky;
  bottom: 0;
  background-color: inherit;
  padding-top: 20px;
  display: flex;
  justify-content: center;
  border-top: 1px solid ${({ theme }) => theme.border || "#eaeaea"};
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 32px;
  cursor: pointer;
  position: absolute;
  top: 10px; /* Adjust as needed */
  right: 10px; /* Adjust as needed */
  color: ${({ theme }) => theme.text || "#333"};
`;

const Tabs = styled.div`
  display: flex;
  border-bottom: 2px solid ${({ theme }) => theme.border || "#eaeaea"};
  overflow: hidden;
  justify-content: center;
`;

const Tab = styled.div`
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  transition: background-color 0.3s;
  color: ${({ theme, active }) => (active ? theme.text : "#bcb8cb")};

  &:hover {
    background-color: ${({ theme }) =>
      theme.post_hover_background || "#f5f5f5"};
  }

  &::after {
    content: "";
    position: absolute;
    bottom: -3px;
    left: 0;
    right: 0;
    height: 2px;
    background-color: #1e2836;
    transform: ${({ active }) => (active ? "scaleX(1)" : "scaleX(0)")};
    transform-origin: bottom left;
    transition: transform 0.3s;
  }

  p {
    margin: 0 0 0 8px;
  }

  .material-icons {
    font-size: 24px;
  }
`;

const TabContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; /* Ensure it uses the full width available */
  max-width: 500px; /* Set a fixed maximum width */
  min-height: 50px; /* Set a fixed minimum height */
  margin-bottom: 20px;
  margin-top: 20px;
`;

export const SubmitButton = styled.button`
  width: 30%;
  padding: 10px;
  background-color: #1e2836;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #2a3a4f; /* Change to a lighter shade on hover */
  }
  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

export const InputContainer = styled.div`
  margin-bottom: 20px;
`;

export const StyledSelect = styled(Select)`
  width: 50%;
  background-color: ${({ theme }) => theme.background || "#f5f5f5"};
`;

const SectionTitle = styled.h4`
  color: ${({ theme }) => theme.text};
  margin-bottom: 10px;
  margin-top: 0;
  text-align: center;
`;

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 15px;
`;

export const CheckBoxInput = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const DatePickerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
`;

const sanitizeFilename = (name) => {
  // Remove invalid file name characters regex
  return name.replace(/[/\\?%*:|"<>]/g, "-");
};

const FromToPopup = ({ isOpen, onClose, search, column }) => {
  const [From, setFrom] = useState("");
  const [To, setTo] = useState("");
  const { theme } = useTheme();
  const [CSVloading, setCSVLoading] = useState(false);
  const [MediaLoading, setMediaLoading] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState({
    value: "Original",
    label: "Original",
  });

  const [mediaSelection, setMediaSelection] = useState({
    videos: false,
    images: false,
  });

  const [selectedTab, setSelectedTab] = useState("csv");

  const toggleSelection = (mediaType) => {
    setMediaSelection((prevState) => ({
      ...prevState,
      [mediaType]: !prevState[mediaType],
    }));
  };

  const handleFromChange = (e) => {
    setFrom(e.target.value);
  };

  const handleToChange = (e) => {
    setTo(e.target.value);
  };

  const handleLanguageChange = (selectedOption) => {
    setSelectedLanguage(selectedOption);
  };

  const handleSubmit = async () => {
    try {
      setCSVLoading(true);
      if (From === "" || To === "") {
        toast.error("Please fill all fields");
        return;
      }

      const from = new Date(From);
      const to = new Date(To);
      if (from >= to) {
        toast.error("From date should be before To date");
        return;
      }

      // Send to backend
      const response = await postAuth("/post/download-csv", {
        from: from.toISOString(),
        to: to.toISOString(),
        searchString: search,
        column_id: column,
        language: selectedLanguage.value,
      });

      if (response.ok) {
        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = downloadUrl;

        const filename = search
          ? `${sanitizeFilename(search)}_posts.csv`
          : "filtered_posts.csv";
        a.download = filename;

        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(downloadUrl);

        toast.success("CSV has been downloaded successfully.");
      } else {
        const errorData = await response.json();
        toast.error(errorData.message || "Failed to download CSV.");
      }
    } catch (error) {
      toast.error("An unexpected error occurred. Please try again.");
      console.error("Error during handleSubmit:", error);
    } finally {
      setCSVLoading(false);
    }
  };

  const handleDownload = async () => {
    try {
      setMediaLoading(true);

      if (From === "" || To === "") {
        toast.error("Please fill all fields");
        return;
      }

      const from = new Date(From);
      const to = new Date(To);
      if (from >= to) {
        toast.error("From date should be before To date");
        return;
      }

      if (!mediaSelection.images && !mediaSelection.videos) {
        toast.error("Please select at least one media type");
        return;
      }

      // Send to backend
      const response = await postAuth("/post/download-column-media", {
        from: from.toISOString(),
        to: to.toISOString(),
        searchString: search,
        mediaSelection,
        column_id: column,
      });

      if (response.ok) {
        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement("a");

        a.href = downloadUrl;
        a.download = "media.zip";

        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(downloadUrl);

        toast.success("Media has been downloaded successfully.");
      } else {
        const errorData = await response.json();
        toast.error(errorData.message || "Failed to download media.");
      }
    } catch (error) {
      toast.error("An unexpected error occurred. Please try again.");
      console.error("Error during handleDownload:", error);
    } finally {
      setMediaLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <Overlay>
      <Modal theme={theme}>
        <ModalHeader>
          <h2>Download Options</h2>
          <CloseButton onClick={onClose}>&times;</CloseButton>
        </ModalHeader>

        <InputContainer $theme={theme}>
          <SectionTitle $theme={theme}>Select Date Interval</SectionTitle>
          <DatePickerContainer>
            <StyledDiv>
              <label htmlFor="from-date">From</label>
              <DatePicker
                id="from-date"
                type="datetime-local"
                value={From}
                onChange={handleFromChange}
                $theme={theme}
              />
            </StyledDiv>
            <StyledDiv>
              <label htmlFor="to-date">To</label>
              <DatePicker
                id="to-date"
                type="datetime-local"
                value={To}
                onChange={handleToChange}
                $theme={theme}
              />
            </StyledDiv>
          </DatePickerContainer>
        </InputContainer>

        <InputContainer></InputContainer>

        <Tabs>
          <Tab
            active={selectedTab === "csv"}
            onClick={() => setSelectedTab("csv")}
          >
            <span className="material-icons">file_download</span>
            <p>CSV</p>
          </Tab>
          <Tab
            active={selectedTab === "media"}
            onClick={() => setSelectedTab("media")}
          >
            <span className="material-icons">photo_library</span>
            <p>Media</p>
          </Tab>
        </Tabs>

        <TabContent>
          {selectedTab === "csv" ? (
            <>
              <h4 style={{ margin: 0, marginRight: "10px" }}>
                Choose Language:
              </h4>
              <StyledSelect
                options={[
                  { value: "Original", label: "Original" },
                  { value: "en", label: "English" },
                  { value: "ar", label: "Arabic" },
                  { value: "es", label: "Spanish" },
                  { value: "fr", label: "French" },
                ]}
                value={selectedLanguage}
                onChange={handleLanguageChange}
                styles={{
                  control: (base, state) => ({
                    ...base,
                    fontSize: "14px",
                    backgroundColor: theme === "dark" ? "#333" : "#fff",
                  }),
                  menu: (base) => ({
                    ...base,
                    backgroundColor: theme === "dark" ? "#333" : "#fff",
                  }),
                  singleValue: (base) => ({
                    ...base,
                    color: theme === "dark" ? "#fff" : "#000",
                  }),
                  option: (base, state) => ({
                    ...base,
                    backgroundColor: state.isFocused
                      ? theme === "dark"
                        ? "#555"
                        : "#ddd"
                      : theme === "dark"
                      ? "#333"
                      : "#fff",
                    color: state.isFocused
                      ? theme === "dark"
                        ? "#fff"
                        : "#000"
                      : theme === "dark"
                      ? "#fff"
                      : "#000",
                    cursor: "pointer",
                  }),
                  input: (base) => ({
                    ...base,
                    color: theme.text,
                  }),
                }}
              />
            </>
          ) : (
            <>
              <h4 style={{ margin: 0, marginRight: "10px" }}>
                Select Media Types:
              </h4>
              <CheckBoxInput>
                <label>
                  <input
                    type="checkbox"
                    checked={mediaSelection.videos}
                    onChange={() => toggleSelection("videos")}
                  />
                  Videos
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={mediaSelection.images}
                    onChange={() => toggleSelection("images")}
                  />
                  Images
                </label>
              </CheckBoxInput>
            </>
          )}
        </TabContent>

        <ModalFooter>
          {selectedTab === "csv" ? (
            CSVloading ? (
              <Spinner />
            ) : (
              <SubmitButton onClick={handleSubmit} disabled={CSVloading}>
                Download CSV
              </SubmitButton>
            )
          ) : MediaLoading ? (
            <Spinner />
          ) : (
            <SubmitButton onClick={handleDownload} disabled={MediaLoading}>
              Download Media
            </SubmitButton>
          )}
        </ModalFooter>
      </Modal>
    </Overlay>
  );
};

export default FromToPopup;
