import { useEffect, useState, useRef } from "react";
import { useAuth } from "./components/authentication/AuthContext/AuthContext";
import { postAuth } from "./globalUtils";

const useUserTimeTracker = () => {
  const { userId } = useAuth();
  const [startTime, setStartTime] = useState(Date.now());
  const hasSentTime = useRef(false); // Prevent multiple submissions

  useEffect(() => {
    const sendTimeToServer = async () => {
      if (!startTime || hasSentTime.current) return; // Prevent multiple calls

      const endTime = Date.now();
      const timeSpent = endTime - startTime; // Time in milliseconds

      console.log("Time spent: ", timeSpent);
      console.log("User ID: ", userId);

      try {
        const response = await postAuth("/auth/record-time", {
          timeSpent,
          userId,
        });
        const data = await response.json();
        console.log("Response from server: ", data);
        hasSentTime.current = true;
      } catch (error) {
        console.error("Failed to send time to server: ", error);
      }
    };

    const handleBeforeUnload = (event) => {
      sendTimeToServer();
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        sendTimeToServer();
      } else if (document.visibilityState === "visible") {
        // Reset startTime and hasSentTime when the user returns to the tab
        setStartTime(Date.now());
        hasSentTime.current = false;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [startTime, userId]);

  return null;
};

export default useUserTimeTracker;
