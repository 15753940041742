import { Popup } from "react-leaflet";
import { formatDateOrTime } from "../UtilesMap/MapUtiles";
import styled from "styled-components";
import { useCallback } from "react";
import L from "leaflet";
import { website } from "../../../globalUtils";
import { getCategoryByCid } from "../UtilesMap/MapUtiles";
import { Marker } from "react-leaflet";
import { createCustomIcon } from "../UtilesMap/MapUtiles";

const PopupContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50px;
  min-width: 150px;
  width: fit-content;
`;

const InnerContent = styled.div`
  text-align: center;

  div {
    margin-bottom: 10px; /* Add margin between elements */
  }

  div:last-child {
    margin-bottom: 0; /* Remove margin from the last element */
  }
`;

export const MyMarker = ({ item, index, handleMarkerClick, categories }) => {
  const category = getCategoryByCid(categories, item.cid);

  const handleMouseOver = useCallback((e) => {
    e.target.openPopup();
  }, []);

  const handleMouseOut = useCallback((e) => {
    setTimeout(() => {
      e.target.closePopup();
    }, 300);
  }, []);

  return (
    <Marker
      key={`${item.pid}-${index}`}
      position={[item.lat, item.lng]}
      icon={createCustomIcon(L, website, category)}
      eventHandlers={{
        click: handleMarkerClick,
        mouseover: handleMouseOver,
        mouseout: handleMouseOut,
      }}
    >
      <Popup>
        {" "}
        <PopupContent>
          <InnerContent>
            <div>{category.label || "Unknown Category"}</div>
            <div>{item?.text || "No details"}</div>
            <div>
              <span>{formatDateOrTime(item?.timestamp || new Date())}</span>
            </div>
          </InnerContent>
        </PopupContent>
      </Popup>
    </Marker>
  );
};
