import React, { useState, useMemo } from "react";
import { useBookmarks } from "./BookmarkContext";
import Post from "../../../post/Post";
import Popup from "../../../post/popup";
import { deletePost } from "../../../localdb/DBUtils";
import styled from "styled-components";
import { post } from "../../../../globalUtils";
import Spinner from "../../../../Spinner";
import ExportCsv from "./ExportCsv";
import { useNavigate } from "react-router-dom";

export const defaultColumn = {
  get_video: true,
  get_image: true,
  get_preview: true,
  get_text_only: true,
};

const HeaderContainer = styled.h3`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 15px;
  gap: 20px;
  box-shadow: 0 4px 4px -3px rgba(0, 0, 0, 0.1);
`;

export const ThemedInput = styled.input`
  background-color: ${({ theme }) => theme.popup_background};
  color: ${({ theme }) => theme.text};
  border-color: ${({ theme }) => theme.border_color};
  width: 91%;
  margin: 0 auto;
  padding: 10px 20px;
`;

const ButtonBase = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: bold;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  gap: 5px;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
`;

const DownloadButton = styled(ButtonBase)`
  background-color: #007bff; /* Blue */
  color: white;

  &:hover {
    background-color: #0056b3; /* Darker blue */
  }
`;

const DeleteButton = styled(ButtonBase)`
  background-color: #dc3545; /* Red */
  color: white;

  &:hover {
    background-color: #c82333; /* Darker red */
  }
`;

export const ExportButton = styled(ButtonBase)`
  background-color: #28a745; /* Green */
  color: white;

  &:hover {
    background-color: #218838; /* Darker green */
  }
`;

export const downloadBlob = (blob, filename) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};

export const BookmarkComponent = () => {
  const { bookmarkedPosts, deleteContextPost } = useBookmarks();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [currentId, setCurrentPost] = useState("");
  const [currentsender, setCurrentSender] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const closePopup = () => setIsPopupOpen(false);

  const handleOpenPopup = (sender_username, post_id) => {
    setCurrentSender(sender_username);
    setCurrentPost(post_id);
    setIsOpen(true);
  };

  const deleteBookmarks = () => {
    if (window.confirm("Are you sure you want to delete all bookmarks?")) {
      bookmarkedPosts.forEach((post) => {
        deleteContextPost(post.post_id, post.main_id);
        deletePost(post.post_id);
      });
    }
  };

  const downloadMedia = async () => {
    try {
      setLoading(true);
      const post_ids = bookmarkedPosts.map((post) => post.id.toString());
      if (post_ids.length === 0) {
        alert("No posts to download.");
        return;
      }

      const response = await post("/post/downloadMedia", post_ids);

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      const blob = await response.blob();

      if (blob.size > 0) {
        downloadBlob(blob, "teledeck_media.zip");
      } else {
        console.error("Received an empty file.");
      }
    } catch (error) {
      console.error("Failed to download file:", error);
    } finally {
      setLoading(false);
    }
  };

  const filteredPosts = useMemo(() => {
    return bookmarkedPosts.filter((post) => {
      return post.text.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }, [bookmarkedPosts, searchTerm]);

  return (
    <div>
      {isOpen && (
        <Popup
          senderusername={currentsender}
          post_id={currentId}
          onClose={() => setIsOpen(false)}
        />
      )}
      <>
        <div className="draggable-input-icon-container">
          <ThemedInput
            type="text"
            className="ColumnNameInput TextArea"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <span
            className="material-icons search-icon"
            style={{
              marginLeft: "10px",
            }}
          >
            search
          </span>
        </div>
        <HeaderContainer>
          <ExportCsv bookmarkedPosts={bookmarkedPosts} />
          {loading ? (
            <Spinner loading={loading} />
          ) : (
            <DownloadButton onClick={downloadMedia}>
              <span className="material-icons">download</span>Download
            </DownloadButton>
          )}
          <DeleteButton onClick={deleteBookmarks}>
            <span className="material-icons">delete</span>Clear
          </DeleteButton>
        </HeaderContainer>

        {filteredPosts.length > 0 ? (
          filteredPosts.map((post) => (
            <div key={post.post_id}>
              <Post
                post={post}
                column={defaultColumn}
                is_a_bookmark={true}
                onOpenPopup={handleOpenPopup}
              />
            </div>
          ))
        ) : (
          <p style={{ marginLeft: "10px" }}>No bookmarks yet.</p>
        )}
      </>
    </div>
  );
};
