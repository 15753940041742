import React from "react";
import styled, { keyframes } from "styled-components";
import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";

countries.registerLocale(enLocale);

const CountryLabelContainer = styled.div`
  position: absolute;
  top: 10px;
  left: 90px;
  background-color: white;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  z-index: 1000;
`;

const CloseButton = styled.span`
  margin-left: 10px;
  cursor: pointer;
  color: red;
`;

const InfoIcon = styled.span`
  font-size: 20px; // Larger size for the icon
  margin-right: 10px; // Space between icon and text
`;

const Text = styled.span`
  display: inline-flex;
  align-items: center; // Align text with icon vertically
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: ${spin} 1s linear infinite;
  margin-right: 10px;
`;

const CountryLabel = ({ country, loading, onClear }) => {
  const countryName = countries.getName(country, "en");

  return (
    <CountryLabelContainer>
      {loading ? (
        <>
          <Spinner />
          <Text>Fetching country data, please wait...</Text>
        </>
      ) : (
        <>
          {country ? (
            <>
              Selected Country: {countryName}
              <CloseButton onClick={onClear}>X</CloseButton>
            </>
          ) : (
            <>
              <InfoIcon className="material-symbols-outlined">info</InfoIcon>
              <Text>
                Double Click on a country to see news from that area only
              </Text>
            </>
          )}
        </>
      )}
    </CountryLabelContainer>
  );
};

export default CountryLabel;
