import React, { useContext, useEffect, useRef, useState } from "react";
import { Draggable } from "@hello-pangea/dnd";
import styled from "styled-components";
import { EventSourceContext } from "../context/EventSourceContext";
import Post from "./post/Post";
import Popup from "./post/popup";
import "../assets/css/Popup.css";
import "../assets/css/chips.css";
import { updateColumnInDB } from "./localdb/DBColumn";
import { website } from "../globalUtils";
import {
  deleteColumnFromAPI,
  updateColumnsToAPIChips,
} from "./utils/ColumnUtils";
import EditPopup from "./column_popups/EditPopup";
import { getPostsFromApi } from "./utils/postUtils";
import { useColumns } from "./ColumnsContext";
import calendarIcon from "../assets/images/calendar.svg";
import whitecalendarIcon from "../assets/images/whiteCalendar.svg";
import { useTheme } from "./themes/ThemeContext";
import FromToPopup from "./column_popups/FromToPopup";
import { toast } from "react-toastify";
import CustomToast from "./utils/CustomToast";
import { useSearch } from "./side-panel/sidepanel-components/global-search/SearchContext";
import { createNewColumn } from "./utils/newColumnUtil";
import Swal from "sweetalert2";
import TranslateColumn from "./utils/TranslateColumn";

const Column = styled.div`
  background-color: ${({ theme }) => theme.column_background};
  margin-left: 10px;
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  width: 300px;
  height: calc(100% - 10px);
  color: ${({ theme }) => theme.column_text};
`;

const ThemedInput = styled.input`
  background-color: ${({ theme }) => theme.popup_background};
  color: ${({ theme }) => theme.text};
  border-color: ${({ theme }) => theme.border_color};
  width: 50%;
`;

const StrongWord = styled.strong`
  color: ${({ theme }) => theme.text};
`;

const ColumnBody = styled.div`
  width: 100%;
  position: relative;
  height: calc(100% - 50px);
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    transition: background-color 0.3s linear;
  }
  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.border_color};
      transition: background-color 0.3s linear;
    }
  }
`;

const ColumnHeader = styled.div`
  box-sizing: border-box;
  padding: 10px;
  background-color: ${({ theme }) => theme.filter_background};
  width: 300px;
  height: 50px;
  border-bottom: 1px solid ${({ theme }) => theme.border_color};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ColumnHeaderText = styled.div`
  font-size: 16px;
  text-align: center;
  width: 150px;
  padding: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ColumnHeaderIcon = styled.i`
  font-size: 24px;
`;

const ColumnHeaderIconClick = styled.i`
  font-size: 20px;
  cursor: pointer;
  &:hover {
    opacity: 0.87;
  }
`;

const ChipContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FiltersDropDown = styled.div`
  background-color: ${({ theme }) => theme.filter_background};
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  top: 0px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  gap: 10px;
  margin-bottom: 2px;
`;

const Chip = styled.span`
  color: ${({ theme }) => theme.column_text};
  padding: 2px;
  border-radius: 3px;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid
    ${({ $active, theme }) =>
      $active ? theme.chip_active : theme.chip_inactive};
  color: ${({ $active, theme }) => ($active ? "#fff" : theme.chip_inactive)};
  background-color: ${({ $active, theme }) =>
    $active ? theme.blue : "transparent"};
`;

const LoadButton = styled.button`
  font-size: 14px;
  font-weight: bold;
  margin-top: 2px;
  padding: 10px;
  background-color: ${({ theme }) => theme.chip_inactive};
  color: ${({ theme }) => theme.text};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  width: 100%;
  &:hover {
    opacity: 0.87;
  }
`;

const SearchHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.filter_background};
`;

export const DatePicker = styled.input`
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ccc;
  padding: 8px ${({ paddingHorizontal }) => paddingHorizontal || "12px"}; /* Default horizontal padding is 12px */

  border-radius: 5px;
  background-color: ${({ theme }) => theme.popup_background};
  color: ${({ theme }) => theme.text};
  border-color: ${({ theme }) => theme.border_color};
  margin-bottom: 5px;
  font-size: 12px;

  &::-webkit-calendar-picker-indicator {
    opacity: 1;
    font-size: ${({ fontSize }) => fontSize || "20px"};
    background-image: ${({ $theme }) =>
      $theme === "light"
        ? `url(${calendarIcon})`
        : `url(${whitecalendarIcon})`};
    background-size: cover;
    cursor: pointer;
  }

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.primary};
    box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.5);
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.text};
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 5px 0;
  min-width: 150px; // Ensures minimum width but can expand
  z-index: 10; // Ensure it's above other content
  top: 100%; // Starts directly below the toggle element
  right: 0; // Align with the right of the toggle element
`;

const DropdownItem = styled.div`
  padding: 8px 20px;
  white-space: nowrap; // Prevents text from wrapping
  cursor: pointer;
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.text};
  &:hover {
    background-color: ${({ theme }) =>
      theme === "dark"
        ? "#555555"
        : "#e0e0e0"}; // Light grey for light mode, dark grey for dark mode
  }
`;

const DraggableColumn = ({ static_column, index }) => {
  const { subscribe, unsubscribe } = useContext(EventSourceContext);
  const [localMessages, setLocalMessages] = useState([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isMoreVertDropdownVisible, setIsMoreVertDropdownVisible] =
    useState(false);
  const [column, setColumn] = useState(static_column);
  const [inputValue, setInputValue] = useState(() => {
    return localStorage.getItem(`inputValue-${column.id}`) || "";
  });
  const [debouncedValue, setDebouncedValue] = useState("");
  const [isEditPopupVisible, setEditPopupVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [currentsender, setCurrentSender] = useState("");
  const [currentId, setCurrentPost] = useState("");
  const { updateColumn, columnsCount, addColumn, removeColumn } = useColumns();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [firstInteractionTime, setFirstInteractionTime] = useState(
    new Date().toLocaleString()
  );
  const [dateValue, setDateValue] = useState("");
  const { theme } = useTheme();
  const columnRef = useRef();
  const [isLoadBeforeDate, setIsLoadBeforeDate] = useState(true);
  const { searchState, globalDate, globalColumns } = useSearch();
  const [language, setLanguage] = useState("original");
  const [isLangPopupVisible, setIsLangPopupVisible] = useState(false);

  const handleDateChange = (event) => {
    setIsLoadBeforeDate(true);
    const localDate = event.target.value.toLocaleString();

    setDateValue(localDate);
  };

  const updateTimestamp = () => {
    const utcDate = new Date();
    const localDate = utcDate.toLocaleString();
    setIsLoadBeforeDate(true);
    setFirstInteractionTime(localDate);
  };

  useEffect(() => {
    const utcDate = new Date();
    const localDate = utcDate.toLocaleString();
    setFirstInteractionTime(localDate);
  }, []);

  const loadMorePosts = async (isLoadBeforeDate) => {
    if (page >= totalPages || loading) return;

    setLoading(true);
    setIsLoadBeforeDate(isLoadBeforeDate);
    const nextPage = page + 1;

    let dateToSend = firstInteractionTime; // Default date
    if (globalDate) {
      // Apply the date only if globalColumns is empty or includes this column's ID
      if (globalColumns.length === 0 || globalColumns.includes(column.id)) {
        dateToSend = new Date(globalDate).toLocaleString();
      }
    } else if (dateValue) {
      dateToSend = new Date(dateValue).toLocaleString();
    }

    const result = await getPostsFromApi({
      column: column,
      search: debouncedValue || inputValue,
      page: nextPage,
      limit: 10,
      date: dateToSend,
      isLoadBeforeDate: isLoadBeforeDate,
    });

    if (result.data && result.data.length > 0) {
      setLocalMessages((prevMessages) =>
        isLoadBeforeDate
          ? [...prevMessages, ...result.data]
          : [...result.data, ...prevMessages]
      );
      setPage(nextPage);
      setTotalPages(result.totalPages || totalPages);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (globalColumns.length === 0) {
      // If globalColumns is empty, apply the search state to all columns
      setDebouncedValue(searchState);
    } else if (globalColumns.includes(column.id)) {
      // If globalColumns is not empty, apply the search state only if the current column's ID is included
      setDebouncedValue(searchState);
    }
  }, [searchState, globalColumns, column.id]);

  const showToast = (
    columnTitle,
    index,
    postText,
    channelTitle,
    isLoudSound,
    postId,
    columnId
  ) => {
    toast(
      <CustomToast
        columnTitle={columnTitle}
        postId={postId}
        columnId={columnId}
        postText={postText}
        channelTitle={channelTitle}
        index={index}
        isLoudSound={isLoudSound}
      />,
      {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        pauseOnHover: true,
        progress: undefined,
        progressStyle: { background: "#1E2836" },
      }
    );
  };

  useEffect(() => {
    const handleMessage = (newMessage) => {
      if (dateValue) {
        return;
      }
      column.channels.forEach(function (channel) {
        if (channel.id === newMessage.sender_id) {
          // console.log("searchState", searchState);
          // console.log("newMessage.text", newMessage.text);
          // console.log("column name", column.name);
          // Check if newMessage.text is a string before calling includes
          if (
            !newMessage.text ||
            typeof newMessage.text !== "string" ||
            !newMessage.text.includes(debouncedValue) ||
            (searchState && !newMessage.text.includes(searchState))
          ) {
            // console.log("returning", newMessage.text.includes(searchState));
            return;
          }

          if (column.get_image && !newMessage.has_image) {
            return;
          }
          if (column.get_preview === 1 && !newMessage.has_preview) {
            return;
          }
          if (column.get_video && !newMessage.has_video) {
            return;
          }
          if (
            column.get_text_only &&
            (newMessage.has_image ||
              newMessage.has_video ||
              newMessage.has_preview)
          ) {
            return;
          }

          newMessage.chat_title = channel.chat_title;
          newMessage.sender_username = channel.sender_username;
          newMessage.image = `${website}${channel.image}`;
          newMessage.isApi = true;
          newMessage.id = newMessage.sender_id + "" + newMessage.post_id;
          setLocalMessages((prevMessages) => [newMessage, ...prevMessages]);

          if (column.notifications) {
            showToast(
              column.name,
              index,
              newMessage.text,
              channel.sender_username,
              column.isLoudSound,
              newMessage.id,
              column.id
            );
          }
        }
      });
    };

    subscribe(handleMessage);
    return () => unsubscribe(handleMessage);
  }, [
    subscribe,
    unsubscribe,
    column.content,
    inputValue,
    column,
    dateValue,
    searchState,
  ]);

  const toggleDropdown = () => {
    if (isMoreVertDropdownVisible) setIsMoreVertDropdownVisible(false);
    setIsDropdownVisible(!isDropdownVisible);
  };
  const toggleMoreVertDropdown = () => {
    if (isDropdownVisible) setIsDropdownVisible(!isDropdownVisible);
    setIsMoreVertDropdownVisible(!isMoreVertDropdownVisible);
  };

  useEffect(() => {
    const fetchColumn = async () => {
      try {
        setColumn(static_column);
      } catch (error) {
        console.error("Failed to fetch column:", error);
      }
    };

    fetchColumn();
  }, [static_column]);

  const handleOpenPopup = (sender_username, post_id) => {
    setCurrentSender(sender_username);
    setCurrentPost(post_id);
    setIsOpen(true);
  };

  const handlePopupEdit = async (column2, type) => {
    // Set the state of the clicked chip and reset others

    const updatedColumn = {
      ...column2,

      channels: column2.channels,

      get_text_only: 0,

      get_image: 0,

      get_video: 0,

      get_preview: 0,
    };

    // Toggle the state of the clicked chip

    updatedColumn[type] = column2[type] ? 0 : 1;

    try {
      setColumn(updatedColumn);

      setForceUpdate((f) => !f); // Toggle forceUpdate to trigger re-render
    } catch (error) {
      console.error("Failed to update column:", error);
    }
  };
  const handleChipClick = async (chipType) => {
    updateTimestamp();
    const updates = {
      get_text_only: 0,
      get_image: column.get_image,
      get_video: column.get_video,
      get_preview: 0,
    };

    // Switch to manage chip selection logic
    switch (chipType) {
      case "textOnly":
        updates.get_text_only = column.get_text_only ? 0 : 1;
        break;
      case "images":
        updates.get_image = column.get_image ? 0 : 1;
        break;
      case "videos":
        updates.get_video = column.get_video ? 0 : 1;
        break;
      case "preview":
        updates.get_preview = column.get_preview ? 0 : 1;
        break;
      default:
        break;
    }

    // If any chip is selected, reset all others, except for images and videos which can be active together unless preview is selected
    if (chipType === "preview" && updates.get_preview === 1) {
      updates.get_text_only = 0;
      updates.get_image = 0;
      updates.get_video = 0;
    } else if (
      (chipType === "images" || chipType === "videos") &&
      (updates.get_image === 1 || updates.get_video === 1)
    ) {
      updates.get_preview = 0; // Reset preview if images or videos are selected
    } else if (chipType === "textOnly" && updates.get_text_only === 1) {
      updates.get_image = 0;
      updates.get_video = 0;
      updates.get_preview = 0;
    }

    const updatedColumn = {
      ...column,
      ...updates,
    };

    setColumn(updatedColumn);
    setForceUpdate((f) => !f); // Toggle forceUpdate to trigger re-render

    try {
      await updateColumnInDB(column.id, updatedColumn);
    } catch (error) {
      console.error("Failed to update column in DB:", error);
    }

    try {
      await updateColumnsToAPIChips(updatedColumn);
    } catch (error) {
      console.error("Failed to update column in API:", error);
    }

    updateColumn(updatedColumn);
  };

  const renderChips = () => {
    return (
      <div className="chips-container">
        <Chip
          $active={column.get_text_only === 1}
          onClick={() => handleChipClick("textOnly")}
          className="material-symbols-outlined chip-icon"
          title="Filter By Text Only"
        >
          title
        </Chip>
        <hr className="vertical-line" />

        <Chip
          $active={column.get_preview === 1}
          onClick={() => handleChipClick("preview")}
          className="material-symbols-outlined  chip-icon"
          title="Filter By Previews"
        >
          preview
        </Chip>
        <hr className="vertical-line" />
        <Chip
          $active={column.get_image === 1}
          onClick={() => handleChipClick("images")}
          className="material-symbols-outlined  chip-icon"
          title="Filter By Images"
        >
          image
        </Chip>
        <Chip
          $active={column.get_video === 1}
          onClick={() => handleChipClick("videos")}
          className="material-symbols-outlined  chip-icon"
          title="Filter By Videos"
        >
          videocam
        </Chip>
      </div>
    );
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(inputValue);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue]);

  const handleInputChange = (event) => {
    updateTimestamp();
    const newValue = event.target.value;
    setInputValue(newValue);
    localStorage.setItem(`inputValue-${column.id}`, newValue); // Use column id to differentiate storage
  };

  useEffect(() => {
    localStorage.setItem("inputValue", inputValue);
  }, [inputValue]);

  useEffect(() => {
    const fetchPosts = async () => {
      let dateToSend = firstInteractionTime; // Default date
      if (globalDate) {
        // Apply the date only if globalColumns is empty or includes this column's ID
        if (globalColumns.length === 0 || globalColumns.includes(column.id)) {
          dateToSend = new Date(globalDate).toLocaleString();
        }
      } else if (dateValue) {
        dateToSend = new Date(dateValue).toLocaleString();
      }

      const posts = await getPostsFromApi({
        column: column,
        search: debouncedValue,
        page: 1,
        limit: 10,
        date: dateToSend,
        isLoadBeforeDate: isLoadBeforeDate,
      });

      if (posts.data) {
        setLocalMessages(posts.data);
        setPage(2);
        setTotalPages(posts.totalPages || 1);
      }
    };

    fetchPosts();
  }, [
    debouncedValue,
    column,
    forceUpdate,
    dateValue,
    searchState,
    globalDate,
    globalColumns,
    isLoadBeforeDate,
  ]);

  const toggleEditPopup = () => setEditPopupVisible(!isEditPopupVisible);

  const [isFromToPopupVisible, setFromToPopupVisible] = useState(false);

  const toggleFromToPopup = () => setFromToPopupVisible(!isFromToPopupVisible);

  const duplicateColumn = async (column) => {
    toggleMoreVertDropdown();

    const { id, user_id, chips, channels, ...newColumnWithoutId } = {
      ...column,
      name: `${column.name} (copy)`,
      column_position: columnsCount + 1,
    };

    // Transform the channel array to contain only channel IDs as strings
    const transformedChannel = channels.map((ch) => ch.id);

    // Add the transformed channel array back to the new column object
    newColumnWithoutId.channels = transformedChannel;

    // Convert integer values to strings
    for (const key in newColumnWithoutId) {
      if (typeof newColumnWithoutId[key] === "number") {
        newColumnWithoutId[key] = newColumnWithoutId[key].toString();
      }
    }

    try {
      const result = await createNewColumn(newColumnWithoutId);
      if (result && result.column) {
        addColumn(result.column);
      } else {
        console.error("Failed to create new column: Invalid response from API");
      }
    } catch (error) {
      console.error("Failed to create new column in API:", error);
    }
  };

  const deleteColumn = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this column!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteColumnFromAPI(column.id);
          removeColumn(column.id); // Update local state to remove the column
          Swal.fire({
            icon: "success",
            title: "Deleted",
            text: "The column has been deleted.",
          });
          toggleMoreVertDropdown();
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Failed to delete the column.",
          });
        }
      }
    });
  };

  const toggleLangPopup = () => {
    setIsLangPopupVisible(true);
  };

  return (
    <Draggable key={column.id} draggableId={column.id.toString()} index={index}>
      {(provided) => (
        <Column
          key={column.id}
          ref={provided.innerRef}
          {...provided.draggableProps}
          id={`column-${column.name}-${index}`}
        >
          <ColumnHeader>
            <ColumnHeaderIcon className="material-icons">
              {column.icon}
            </ColumnHeaderIcon>{" "}
            <ColumnHeaderText {...provided.dragHandleProps}>
              {column.name}
            </ColumnHeaderText>
            <ColumnHeaderIconClick
              className="material-icons"
              onClick={toggleDropdown}
            >
              tune
            </ColumnHeaderIconClick>
            <ColumnHeaderIconClick
              className="material-icons"
              onClick={toggleMoreVertDropdown}
            >
              more_vert
            </ColumnHeaderIconClick>
          </ColumnHeader>
          {isDropdownVisible && (
            <FiltersDropDown>
              <SearchHeader>
                <div className="draggable-input-icon-container">
                  <div className="left-side-search">
                    <ThemedInput
                      type="text"
                      className="DraggableColumnNameInput TextArea"
                      placeholder="Search..."
                      value={inputValue}
                      onChange={handleInputChange}
                      title="Search For A Term"
                    />
                    <span className="material-icons search-icon">search</span>
                  </div>
                  <div className="right-side-icons">
                    <span
                      className="material-icons column-header-icon"
                      onClick={toggleLangPopup}
                      title="Translate Column Text"
                    >
                      translate
                    </span>
                    <span
                      className="material-icons column-header-icon"
                      onClick={() => {
                        toggleFromToPopup();
                      }}
                      title="Download Column Media or CSV"
                    >
                      download
                    </span>
                    {isLangPopupVisible && (
                      <TranslateColumn
                        setLanguage={setLanguage}
                        isOpen={isLangPopupVisible}
                        setIsOpen={setIsLangPopupVisible}
                      />
                    )}
                  </div>
                </div>
                <DatePicker
                  type="datetime-local"
                  value={dateValue}
                  onChange={handleDateChange}
                  className="date-time-picker"
                  $theme={theme}
                  title="Filter By Date"
                />
                {isFromToPopupVisible && (
                  <FromToPopup
                    isOpen={isFromToPopupVisible}
                    onClose={toggleFromToPopup}
                    search={inputValue}
                    column={column.id}
                  />
                )}
              </SearchHeader>

              <ChipContainer>
                {" "}
                <h5 className="column-header-filter-title">
                  <StrongWord>Filters</StrongWord>
                </h5>
                {renderChips()}
              </ChipContainer>
            </FiltersDropDown>
          )}
          {isMoreVertDropdownVisible && (
            <div style={{ position: "relative" }}>
              <DropdownMenu>
                <DropdownItem onClick={toggleEditPopup} theme={theme}>
                  Edit Column
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    duplicateColumn(column);
                  }}
                  theme={theme}
                >
                  Duplicate Column
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    deleteColumn(column.id);
                  }}
                  theme={theme}
                >
                  Delete Column
                </DropdownItem>
              </DropdownMenu>
            </div>
          )}
          <ColumnBody ref={columnRef}>
            {dateValue && !loading && (
              <LoadButton
                onClick={() => {
                  loadMorePosts(false);
                }}
              >
                Load More
              </LoadButton>
            )}
            {localMessages.map((msg, index) => (
              <div key={index} data-msg-index={index}>
                <Post
                  column={column}
                  post={msg}
                  onOpenPopup={handleOpenPopup}
                  newLanguage={language}
                />
              </div>
            ))}
            {isOpen && (
              <Popup
                senderusername={currentsender}
                post_id={currentId}
                onClose={() => setIsOpen(false)}
              />
            )}
            {page < totalPages && !loading && (
              <LoadButton
                onClick={() => {
                  loadMorePosts(true);
                }}
              >
                Load More
              </LoadButton>
            )}
          </ColumnBody>

          {isEditPopupVisible && (
            <EditPopup
              column={column}
              onClose={toggleEditPopup}
              onSave={handlePopupEdit}
            />
          )}
        </Column>
      )}
    </Draggable>
  );
};

export default DraggableColumn;
