import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { getAuth, deleteAuth, putAuth } from "../../../../../globalUtils";
import styled from "styled-components";

const PaginationControls = styled.div`
  display: flex;
  justify-content: center;
  align-items: center; /* Center align items vertically */
  margin-top: 20px;

  & > button {
    margin: 0 5px;
  }

  .page-info {
    margin: 0 10px;
    font-weight: bold;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column; /* Stack buttons vertically */
  gap: 10px; /* Space between buttons */
`;

const Table = () => {
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Adjust this value for the number of items per page
  const [filteredData, setFilteredData] = useState([]); // To store filtered data

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await getAuth(`/organization/organization-users`);
      const responseData = await response.json();
      setFilteredData(responseData.data); // Initially set filtered data to be the same as fetched data
      setCount(responseData.total);
    } catch (error) {
      console.error("Failed to fetch users:", error);
      setFilteredData([]); // Clear filtered data if fetch fails
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const deleteUser = async (id) => {
    try {
      const response = await deleteAuth(`/admin_user/${id}`);
      await response.json();
      if (response.ok) {
        alert("User deleted successfully");
        const updatedUsers = filteredData.filter((user) => user.id !== id);
        setFilteredData(updatedUsers);
      }
    } catch (error) {
      console.error("Failed to delete user:", error);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const totalPages = Math.ceil(count / itemsPerPage);

  function formatDate(apiDate) {
    const date = new Date(apiDate);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return date.toLocaleDateString(undefined, options);
  }

  const VerifyUser = async (email) => {
    try {
      const response = await putAuth(`/organization/verify`, {
        email: email,
      });
      const responseData = await response.json();
      if (responseData.status === "success") {
        alert("User verified successfully");
        fetchData();
      } else {
        alert(responseData.message);
      }
    } catch (error) {
      console.error("Failed to verify user:", error);
    }
  };

  return (
    <>
      <h1
        style={{
          textAlign: "center",
        }}
      >
        Team Members Table
      </h1>
      <p style={{ marginBottom: "20px" }}>Number Of Team Members: {count}</p>
      <div className="data-table-diagram">
        {loading ? (
          <p>Loading...</p>
        ) : (
          <>
            <table>
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                  <th>Role</th>
                  <th>Verification</th>
                  <th>Total Time Spent (hours)</th>
                  <th>Creation Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredData?.length > 0 ? (
                  filteredData.map((item, index) => (
                    <tr key={`${item.id}-${index}`}>
                      <td>{item.first_name || "N/A"}</td>
                      <td>{item.last_name || "N/A"}</td>
                      <td>{item.email}</td>
                      <td>{item.phone || "N/A"}</td>
                      <td>{item.role || "N/A"}</td>
                      <td>{item.isVerified ? "Verified" : "Not Verified"}</td>
                      <td>{item.total_time_spent || "N/A"}</td>
                      <td>
                        {item.created_at ? formatDate(item.created_at) : "N/A"}
                      </td>
                      <td>
                        {item.role === "admin" || item.role === "business" ? (
                          <p
                            style={{
                              textAlign: "center",
                            }}
                          >
                            No Actions for Admin
                          </p>
                        ) : (
                          <ButtonContainer>
                            {!item.isVerified && (
                              <Button
                                variant="contained"
                                onClick={() => {
                                  VerifyUser(item.email);
                                }}
                              >
                                Verify
                              </Button>
                            )}
                            <Button
                              onClick={() => {
                                if (
                                  window.confirm(
                                    "Are you sure you want to delete this user?"
                                  )
                                ) {
                                  deleteUser(item.id);
                                }
                              }}
                              variant="contained"
                              sx={{
                                backgroundColor: "red",
                                color: "#fff",
                                "&:hover": {
                                  backgroundColor: "#cc0000",
                                },
                              }}
                              startIcon={<DeleteIcon />}
                            >
                              Delete
                            </Button>
                          </ButtonContainer>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="8"
                      style={{
                        textAlign: "center",
                      }}
                    >
                      No Users in Your Organization
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <PaginationControls>
              <Button
                variant="contained"
                onClick={() => handlePageChange(1)}
                disabled={currentPage === 1}
              >
                First
              </Button>
              <Button
                variant="contained"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </Button>
              <span className="page-info">
                Page {currentPage} of {totalPages}
              </span>
              <Button
                variant="contained"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </Button>
              <Button
                variant="contained"
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages}
              >
                Last
              </Button>
            </PaginationControls>
          </>
        )}
      </div>
    </>
  );
};

export default Table;
