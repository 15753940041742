import React, { useState, useCallback, useEffect, useRef } from "react";
import debounce from "lodash/debounce";
import { fetchChannelsWithTags } from "../../utils/channelSearchUtil";
import { website } from "../../../globalUtils";
import styled from "styled-components";

const ThemedInput = styled.input.attrs({
  className: "chooseChannel",
})`
  background-color: ${({ theme }) => theme.popup_background};
  color: ${({ theme }) => theme.text};
  border-color: ${({ theme }) => theme.border_color};
  &:focus {
    border-color: ${({ theme }) => theme.blue};
  }
`;

const DropdownMenu = styled.div`
  background-color: ${({ theme }) => theme.popup_background};
  color: ${({ theme }) => theme.text};
  border: 1px solid ${({ theme }) => theme.border_color};
`;

const DropdownItem = styled.div`
  &:hover {
    background-color: ${({ theme }) => theme.hover_background};
  }
`;

const TagItem = styled.button`
  color: ${({ theme }) => theme.text};
  background-color: ${({ theme }) => theme.chip_active};
  &:hover {
    background-color: ${({ theme }) => theme.delete_chip};
  }
`;

const ChannelItem = styled.div`
  &:hover {
    background-color: ${({ theme }) => theme.hover_background};
  }
`;

const FixedFooter = styled.div`
  background-color: ${({ theme }) => theme.popup_background};
  &:hover {
    background-color: ${({ theme }) => theme.hover_background};
  }
`;

const ScrollableDiv = styled.div`
  /* Webkit browsers (Chrome, Safari) */
  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.chip_inactive};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.background};
    border-radius: 10px;
    border: 3px solid ${({ theme }) => theme.chip_inactive}; /* Optional: adds space around the thumb */
  }

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: ${({ theme }) => theme.background};
  ${({ theme }) => theme.chip_inactive};
`;

const ArrowItem = styled.button`
  color: ${({ theme }) => theme.arrow_color};
`;

const SecondColumn = ({
  channels,
  onAddChannel,
  chosenChannels,
  setChannels,
  deactivateRegularColumn,
}) => {
  const [tagOptions, setTagOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);
  const tagsContainerRef = useRef(null);

  const handleSearch = async (query, tags) => {
    setLoading(true);
    const tagIds = tags.map((tag) => tag.id);
    const chosenChannelIds = chosenChannels.map((channel) => channel.id);

    if (query.startsWith("#")) {
      // Fetch tags only
      const data = await fetchChannelsWithTags(query.slice(1), tagIds, []);
      if (data) {
        const newTagOptions = data.filteredTags
          .map((tag) => ({
            id: tag.id,
            name: `#${tag.name}`,
          }))
          .filter(
            (tag) =>
              !selectedTags.some((selectedTag) => selectedTag.id === tag.id)
          );
        setTagOptions(newTagOptions);
        setChannels([]);
      } else {
        setTagOptions([]);
        setChannels([]);
      }
    } else {
      // Fetch both channels and tags
      const data = await fetchChannelsWithTags(query, tagIds, chosenChannelIds);
      if (data) {
        const newTagOptions = data.filteredTags
          .map((tag) => ({
            id: tag.id,
            name: `#${tag.name}`,
          }))
          .filter(
            (tag) =>
              !selectedTags.some((selectedTag) => selectedTag.id === tag.id)
          );
        setTagOptions(newTagOptions);
        setChannels(data.channels);
      } else {
        setTagOptions([]);
        setChannels([]);
      }
    }
    setLoading(false);
  };

  const debouncedSearch = useCallback(
    debounce((query, tags) => {
      handleSearch(query, tags);
    }, 300),
    [selectedTags, chosenChannels]
  );

  const handleInputChange = (event) => {
    const query = event.target.value;
    setQuery(query);
    setLoading(true);
    debouncedSearch(query, selectedTags);
    setShowDropdown(true);
  };

  const handleInputFocus = () => {
    if (query && (tagOptions.length > 0 || loading)) {
      setShowDropdown(true);
    }
  };

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      inputRef.current &&
      !inputRef.current.contains(event.target)
    ) {
      setShowDropdown(false);
    }
  };

  const handleTagClick = async (tag) => {
    if (!selectedTags.find((t) => t.id === tag.id)) {
      const updatedSelectedTags = [...selectedTags, tag];
      setSelectedTags(updatedSelectedTags);
      setTagOptions((prev) => prev.filter((t) => t.id !== tag.id));
      setQuery("");
      setShowDropdown(false);
      setLoading(true);
      const data = await fetchChannelsWithTags(
        "",
        updatedSelectedTags.map((t) => t.id)
      );
      if (data) {
        setChannels(data.channels);
      } else {
        setChannels([]);
      }
      setLoading(false);
    }
    updateArrowsVisibility();
  };

  const handleTagDelete = async (tag) => {
    const updatedSelectedTags = selectedTags.filter((t) => t.id !== tag.id);
    setSelectedTags(updatedSelectedTags);
    setTagOptions((prev) => [...prev, tag]);
    setLoading(true);
    const data = await fetchChannelsWithTags(
      query,
      updatedSelectedTags.map((t) => t.id)
    );
    if (data) {
      setChannels(data.channels);
    } else {
      setChannels([]);
    }
    if (updatedSelectedTags.length === 0 && query === "") {
      setChannels([]);
    }
    setLoading(false);
    updateArrowsVisibility();
  };

  const scrollTags = (direction) => {
    if (tagsContainerRef.current) {
      const scrollAmount = direction === "left" ? -100 : 100;
      tagsContainerRef.current.scrollBy({
        left: scrollAmount,
        behavior: "smooth",
      });
    }
  };

  const updateArrowsVisibility = () => {
    if (tagsContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = tagsContainerRef.current;
      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft + clientWidth < scrollWidth);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    debouncedSearch(query, selectedTags);
  }, [query, selectedTags]);

  const handleChannelClick = (channel) => {
    setChannels((prevChannels) =>
      prevChannels.filter((c) => c.id !== channel.id)
    );
    onAddChannel(channel);
  };

  return (
    <div className={deactivateRegularColumn ? "" : "RegularColumn"}>
      <div className="ChooseChannelDiv">
        <h3 className="ColumnHeader">Choose Channels</h3>
        <div className="chooseChannelContainer">
          <ThemedInput
            className="TextArea chooseChannel"
            type="text"
            placeholder="Search channel names or tags..."
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            value={query}
            ref={inputRef}
          />
          {showDropdown && query && (
            <DropdownMenu
              ref={dropdownRef}
              className={`DropdownMenu ${
                tagOptions.length > 0 || loading ? "show" : ""
              }`}
            >
              {loading ? (
                <div className="LoadingMessage">Loading...</div>
              ) : (
                tagOptions.map((tag) => (
                  <DropdownItem
                    key={tag.id}
                    className="DropdownItem"
                    onClick={() => handleTagClick(tag)}
                  >
                    <span>{tag.name}</span>
                  </DropdownItem>
                ))
              )}
            </DropdownMenu>
          )}
        </div>
        <div className="TagsContainer">
          {showLeftArrow && (
            <i
              className="material-icons ArrowIcon left"
              onClick={() => scrollTags("left")}
            >
              arrow_back
            </i>
          )}
          <div
            className="Tags"
            ref={tagsContainerRef}
            onScroll={updateArrowsVisibility}
          >
            {selectedTags.map((tag) => (
              <TagItem
                key={tag.id}
                className="TagButton"
                onClick={() => handleTagDelete(tag)}
              >
                {tag.name}
              </TagItem>
            ))}
          </div>
          {showRightArrow && (
            <i
              className="material-icons ArrowIcon right"
              onClick={() => scrollTags("right")}
            >
              arrow_forward
            </i>
          )}
        </div>
      </div>
      <ScrollableDiv className="ChannelsContainer">
        {channels.map((channel) => (
          <ChannelItem
            key={channel.id}
            className="Channel"
            onClick={() => handleChannelClick(channel)}
          >
            <div className="ChannelElement">
              <div className="ChannelProfileandName">
                <div>
                  <img
                    src={website + channel.image}
                    alt="Channel Icon"
                    className="ChannelIcon"
                  />
                </div>
                <div className="ChannelName">
                  <span className="channel_name">{channel.chat_title}</span>
                  <br />
                  <span className="channel_username">
                    @{channel.sender_username}
                  </span>
                </div>
              </div>
              <ArrowItem className="ArrowRightButton">
                <i className="material-icons">arrow_right</i>
              </ArrowItem>
            </div>
          </ChannelItem>
        ))}
        {/* <FixedFooter className="FixedFooter">
                    <button className="AddAllButton">
                        Add All
                        <i className="material-icons">arrow_right</i>
                    </button>
                </FixedFooter> */}
      </ScrollableDiv>
    </div>
  );
};

export default SecondColumn;
