import { getAuth, postAuth } from "../../../globalUtils";

const filterParams = (params) => {
  return Object.fromEntries(
    Object.entries(params).filter(([_, value]) => value != null)
  );
};

// getting map posts
export const getPosts = async (
  postsCount,
  selectedCategoryValues,
  countryCode,
  center,
  radius,
  startDate,
  endDate
) => {
  try {
    const filteredParams = filterParams({
      postsCount,
      selectedCategoryValues,
      countryCode,
      center,
      radius,
      startDate,
      endDate,
    });

    // Make an HTTP POST request to the server
    const response = await postAuth("/location/map-posts", filteredParams);

    // If the request is successful, parse the JSON response
    if (response.ok) {
      const data = await response.json();
      // Update the state with the fetched data
      return data.data;
    } else {
      // Handle errors, e.g., when response is not ok
      const errorData = await response.json();
      console.error("Failed to fetch posts:", errorData);
      throw new Error("Failed to fetch posts: " + errorData.message);
    }
  } catch (error) {
    // Log and potentially handle errors e.g., showing an error message to the user
    console.error("Error fetching posts:", error);
  }
};

// get full sidebar posts
export const getFullPosts = async (
  limit,
  page,
  selectedCategoryValues,
  countryCode,
  startDate,
  endDate
) => {
  try {
    const params = new URLSearchParams();

    // Add parameters conditionally if they are not null or undefined
    if (limit) params.append("limit", limit);
    if (page) params.append("page", page);
    if (countryCode) params.append("countryCode", countryCode);
    if (startDate) params.append("startDate", startDate);
    if (endDate) params.append("endDate", endDate);

    // Add each category value as repeated parameters
    selectedCategoryValues.forEach((value) => {
      if (value) params.append("categoryId", value);
    });

    // Convert params to a query string
    const queryString = params.toString();

    // Make an HTTP GET request to the server with query parameters
    const response = await getAuth(`/location/posts?${queryString}`);

    // Handle the response
    if (response.ok) {
      const data = await response.json();
      return data.data;
    } else {
      const errorData = await response.json();
      console.error("Failed to fetch posts:", errorData);
      throw new Error("Failed to fetch posts: " + errorData.message);
    }
  } catch (error) {
    console.error("Error fetching posts:", error);
  }
};

// fetch categories
export const getCategories = async () => {
  try {
    const response = await getAuth("/post/categories");

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error("Error fetching categories:", error);
  }
};

// process them to be used in the dropdown
export const fetchCategories = async (setCategories) => {
  try {
    const fetchedCategories = await getCategories();
    const formattedCategories = fetchedCategories.map((category) => ({
      value: category.id,
      label: category.category,
      icon: category.icon,
    }));
    setCategories(formattedCategories);
  } catch (error) {
    console.error("Failed to fetch categories:", error);
  }
};

// extract category object by id
export const getCategoryByCid = (categories, cid) => {
  const category = categories.find((category) => category.value === cid);
  return category || { title: "conflict", icon: "/icon/conflict.jpg" };
};

export const scrollToAndHighlightPost = (postId) => {
  const elementId = `post-${postId}`;
  const element = document.getElementById(elementId);

  if (element) {
    // Check if the element is already in view
    const rect = element.getBoundingClientRect();
    const isInView =
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth);

    if (!isInView) {
      // Smoothly scroll to the post element
      element.scrollIntoView({
        behavior: "smooth",
        block: "center", // Adjusted to "center" for better visibility
        inline: "center", // Adjusted to "center" for better visibility
      });
    }

    // Apply a temporary styling effect to highlight the post
    element.style.transition = "filter 1s";
    element.style.filter = "brightness(80%)";
    setTimeout(() => {
      element.style.filter = "none";
    }, 1000);
  }
};

export const formatDateOrTime = (isoDateString) => {
  const date = new Date(isoDateString);
  date.setHours(date.getHours() - 3);
  const now = new Date();
  const isWithin24Hours = now - date < 24 * 60 * 60 * 1000;
  return isWithin24Hours
    ? date.toLocaleTimeString(undefined, {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })
    : date.toLocaleString(undefined, {
        year: "2-digit",
        month: "numeric",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
};

export const createCustomIcon = (L, website, category) => {
  if (!category) {
    return L.icon({
      iconUrl: `${website}/icon/conflict.jpg`,
      iconSize: [40, 40],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
    });
  }

  return L.icon({
    iconUrl: `${website}${category?.icon}`,
    iconSize: [40, 40],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
  });
};

export const getAddress = async (lat, lon, setCountryCode, setLoading) => {
  const url = "https://python.data.teledeck.news/get_cc";

  const payload = {
    lng: lon.toString(),
    lat: lat.toString(),
  };

  try {
    setLoading(true);
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    setCountryCode(data.country_code);
  } catch (error) {
    console.error("Error fetching address:", error);
  } finally {
    setLoading(false);
  }
};

const postCache = new Map();

export const handleMarkerClick = async (
  postId,
  toggleSidebar,
  isDefaultPostScenario,
  setDefaultPosts,
  setFilteredPosts
) => {
  try {
    let post;
    if (postCache.has(postId)) {
      post = postCache.get(postId);
    } else {
      post = await getPostById(postId);
      postCache.set(postId, post);
    }

    const updatePosts = (posts) => {
      const postExists = posts.some((p) => p.id === postId);
      return postExists ? posts : [post, ...posts];
    };

    if (isDefaultPostScenario) {
      setDefaultPosts((prevPosts) => updatePosts(prevPosts));
    } else {
      setFilteredPosts((prevPosts) => updatePosts(prevPosts));
    }
    toggleSidebar(); // Move this outside the conditional block
    setTimeout(() => {
      scrollToAndHighlightPost(postId);
    }, 300);
  } catch (error) {
    console.error("Error during marker click handling:", error);
  }
};

// get a single post by id
export const getPostById = async (id) => {
  try {
    const response = await getAuth(`/location/post-info/${id}`);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error("Error fetching categories:", error);
  }
};

// threshold for when to call the API
const calculateThresholds = (zoomLevel) => {
  let distanceThreshold, radiusPercentageThreshold;

  if (zoomLevel <= 5) {
    // Global view
    distanceThreshold = 800000; // 100 kilometers
    radiusPercentageThreshold = 0.5; // 50%
  } else if (zoomLevel <= 10) {
    // Country view
    distanceThreshold = 50000; // 300 kilometers
    radiusPercentageThreshold = 0.3; // 30%
  } else if (zoomLevel <= 15) {
    // City view
    distanceThreshold = 10000; // 10 kilometer
    radiusPercentageThreshold = 0.2; // 20%
  } else {
    // Street view
    distanceThreshold = 1000; // 1 kilometer
    radiusPercentageThreshold = 0.1; // 10%
  }

  return { distanceThreshold, radiusPercentageThreshold };
};

export const shouldFetch = (
  oldCenter,
  oldRadius,
  newCenter,
  newRadius,
  currentZoom
) => {
  const { distanceThreshold, radiusPercentageThreshold } =
    calculateThresholds(currentZoom);
  const centerChanged = oldCenter.distanceTo(newCenter) > distanceThreshold;
  const radiusChanged =
    Math.abs(oldRadius - newRadius) / oldRadius > radiusPercentageThreshold;
  return centerChanged || radiusChanged;
};
