import React from "react";
import { ContentRow } from "./ContentRow";
import { Link } from "react-router-dom";
const Subscription = () => {
  return (
    <>
      <ContentRow title={"Subscription"}>
        <Link to="/bundles">
          <button className="bundleLink">See our Bundles Here</button>
        </Link>
      </ContentRow>
    </>
  );
};

export default Subscription;
