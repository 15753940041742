import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./components/authentication/AuthContext/AuthContext";
import { ToastContainer } from "react-toastify";
import { Toaster } from "sonner";
import "react-toastify/dist/ReactToastify.css";
import { SearchProvider } from "./components/side-panel/sidepanel-components/global-search/SearchContext";
import { BookmarkProvider } from "./components/side-panel/sidepanel-components/bookmarks/BookmarkContext";
import { ThemeProvider } from "./components/themes/ThemeContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider>
    <AuthProvider>
      <BookmarkProvider>
        <SearchProvider>
          <ToastContainer limit={2} style={{ zIndex: 10 }} />
          <Toaster richColors position="top-center" />
          <App />
        </SearchProvider>
      </BookmarkProvider>
    </AuthProvider>
  </ThemeProvider>
);

const link = document.createElement("link");
link.href = "https://fonts.googleapis.com/icon?family=Material+Icons";
link.rel = "stylesheet";
document.head.appendChild(link);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
