import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SidebarContext } from "../../context/SidebarContext";
import {
  MdOutlineDashboard, // for Dashboard
  MdOutlinePeopleAlt, // for Users
  MdOutlineLiveTv, // for Channels
  MdOutlineAssignmentTurnedIn, // for Channel Requests
  MdOutlineCorporateFare, // for Organizations
  MdOutlineGroupAdd, // for Add Organization
  MdOutlinePersonAddAlt, // for Assign User To Org
  MdOutlineLabel,
  MdOutlineHome,
  MdOutlineLogout,
} from "react-icons/md";
import "./Sidebar.scss";
import { MdMenu } from "react-icons/md";
import { useAuth } from "../../../components/authentication/AuthContext/AuthContext";
import { handleLogout } from "../../../components/authentication/Logout/Logout_utils";

const Sidebar = () => {
  const { isSidebarOpen, toggleSidebar } = useContext(SidebarContext);
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);

  const { role } = useAuth();

  const navbarRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();

  const handleClickOutside = (event) => {
    if (
      navbarRef.current &&
      !navbarRef.current.contains(event.target) &&
      event.target.className !== "sidebar-toggle"
    ) {
      setIsMobileSidebarOpen(false);
    }
  };
  const redirectToHomeWithRefresh = () => {
    navigate("/"); // Navigate to home
    window.location.reload(); // Refresh the page
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <button
        className="sidebar-toggle"
        onClick={() => setIsMobileSidebarOpen(!isMobileSidebarOpen)}
      >
        <MdMenu size={24} color="black" />{" "}
      </button>

      <nav
        className={`sidebar ${
          isSidebarOpen || isMobileSidebarOpen ? "sidebar-show" : ""
        }`}
        ref={navbarRef}
      >
        <div className="sidebar-top">
          <div className="sidebar-brand">
            <span className="sidebar-brand-text">Teledeck</span>
          </div>
          <div>
            <h3>
              {role === "admin"
                ? "Welcome, Administrator"
                : "Welcome, Business Admin"}
            </h3>
          </div>
        </div>

        <div className="sidebar-body">
          <div
            className="sidebar-menu"
            style={{
              maxHeight: "500px",
              overflowY: "auto",
              scrollbarWidth: "thin",
              scrollbarColor: "#adb5bd #f8f9fa",
            }}
          >
            <ul className="menu-list">
              {role === "admin" && (
                <>
                  <li className="menu-item">
                    <Link
                      to="/admin"
                      className={
                        location.pathname === "/admin"
                          ? "menu-link active"
                          : "menu-link"
                      }
                    >
                      <span className="menu-link-icon">
                        <MdOutlineDashboard size={18} />
                      </span>
                      <span className="menu-link-text">Dashboard</span>
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link
                      to="/admin/users"
                      className={
                        location.pathname === "/admin/users"
                          ? "menu-link active"
                          : "menu-link"
                      }
                    >
                      <span className="menu-link-icon">
                        <MdOutlinePeopleAlt size={20} />
                      </span>
                      <span className="menu-link-text">Users</span>
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link
                      to="/admin/channels"
                      className={
                        location.pathname === "/admin/channels"
                          ? "menu-link active"
                          : "menu-link"
                      }
                    >
                      <span className="menu-link-icon">
                        <MdOutlineLiveTv size={20} />
                      </span>
                      <span className="menu-link-text">Channels</span>
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link
                      to="/admin/channel-requests"
                      className={
                        location.pathname === "/admin/channel-requests"
                          ? "menu-link active"
                          : "menu-link"
                      }
                    >
                      <span className="menu-link-icon">
                        <MdOutlineAssignmentTurnedIn size={20} />
                      </span>
                      <span className="menu-link-text">Channel Requests</span>
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link
                      to="/admin/organizations"
                      className={
                        location.pathname === "/admin/organizations"
                          ? "menu-link active"
                          : "menu-link"
                      }
                    >
                      <span className="menu-link-icon">
                        <MdOutlineCorporateFare size={20} />
                      </span>
                      <span className="menu-link-text">Organizations</span>
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link
                      to="/admin/add-organization"
                      className={
                        location.pathname === "/admin/add-organization"
                          ? "menu-link active"
                          : "menu-link"
                      }
                    >
                      <span className="menu-link-icon">
                        <MdOutlineGroupAdd size={20} />
                      </span>
                      <span className="menu-link-text">Add Organization</span>
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link
                      to="/admin/assign"
                      className={
                        location.pathname === "/admin/assign"
                          ? "menu-link active"
                          : "menu-link"
                      }
                    >
                      <span className="menu-link-icon">
                        <MdOutlinePersonAddAlt size={20} />
                      </span>
                      <span className="menu-link-text">Assign User To Org</span>
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link
                      to="/admin/tags"
                      className={
                        location.pathname === "/admin/tags"
                          ? "menu-link active"
                          : "menu-link"
                      }
                    >
                      <span className="menu-link-icon">
                        <MdOutlineLabel size={20} />
                      </span>
                      <span className="menu-link-text">Tags</span>
                    </Link>
                  </li>
                </>
              )}

              {role === "business" && (
                <li className="menu-item">
                  <Link
                    to="/admin/business-users"
                    className={
                      location.pathname === "/admin/business-users"
                        ? "menu-link active"
                        : "menu-link"
                    }
                  >
                    <span className="menu-link-icon">
                      <MdOutlinePeopleAlt size={20} />
                    </span>
                    <span className="menu-link-text">Organization Users</span>
                  </Link>
                </li>
              )}
            </ul>
          </div>

          <div className="sidebar-menu sidebar-menu2">
            <ul className="menu-list">
              <li className="menu-item">
                <Link to="/" className="menu-link">
                  <span className="menu-link-icon">
                    <MdOutlineHome size={20} />
                  </span>
                  <span
                    onClick={redirectToHomeWithRefresh}
                    className="menu-link-text"
                  >
                    Home
                  </span>
                </Link>
              </li>
              <li className="menu-item">
                <Link to="#" className="menu-link" onClick={handleLogout}>
                  <span className="menu-link-icon">
                    <MdOutlineLogout size={20} />
                  </span>
                  <span className="menu-link-text">Logout</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Sidebar;
