const DB_NAME = "TelegramDeckDB";
const DB_VERSION = 5;
let dbInstance = null;

function openDb() {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, DB_VERSION);
    request.onerror = (event) =>
      reject("Database error: " + event.target.errorCode);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;

      // Delete the 'posts' object store if it exists
      if (db.objectStoreNames.contains("posts")) {
        db.deleteObjectStore("posts");
      }

      // Create object stores if they don't exist
      if (!db.objectStoreNames.contains("columns")) {
        db.createObjectStore("columns", { keyPath: "id" });
      }
      if (!db.objectStoreNames.contains("channels")) {
        db.createObjectStore("channels", { keyPath: "id" });
      }
      if (!db.objectStoreNames.contains("bookmarked_posts")) {
        db.createObjectStore("bookmarked_posts", { keyPath: "post_id" });
      }
    };

    request.onsuccess = (event) => {
      dbInstance = event.target.result;
      resolve(dbInstance);
    };
  });
}

function addPost(post) {
  // Check if post_id is valid
  if (!post.post_id) {
    console.error("Invalid post_id, cannot add post:", post);
    return Promise.resolve(false); // Return false if post_id is invalid
  }

  return new Promise((resolve, reject) => {
    withStore("readwrite", ({ db, transaction }) => {
      const store = transaction.objectStore("bookmarked_posts");

      // Check if the post already exists
      const checkRequest = store.get(post.post_id);
      checkRequest.onsuccess = () => {
        if (checkRequest.result) {
          resolve(false); // Post exists, resolve with false
        } else {
          // Post does not exist, proceed to add it
          const addRequest = store.add(post);
          addRequest.onsuccess = () => {
            resolve(true); // Post added, resolve with true
          };
          addRequest.onerror = (e) => {
            console.error("Failed to add post:", e.target.error);
            resolve(false); // Failed to add, resolve with false
          };
        }
      };
      checkRequest.onerror = (e) => {
        console.error("Error checking for post existence:", e.target.error);
        reject(e.target.error); // Reject the promise on check error
      };
    }).catch((error) => {
      console.error("Transaction failed:", error);
      reject(error); // Reject the promise on transaction error
    });
  });
}


function getPost(postId) {
  return new Promise((resolve, reject) => {
    withStore("readonly", ({ db, transaction }) => {
      const store = transaction.objectStore("bookmarked_posts");
      const request = store.get(postId);
      request.onsuccess = () => resolve(request.result);
      request.onerror = () => reject("Failed to get post: " + request.error);
    });
  });
}

function getAllPosts() {
  return new Promise((resolve, reject) => {
    withStore("readonly", ({ db, transaction }) => {
      const store = transaction.objectStore("bookmarked_posts");
      const request = store.getAll(); // Using getAll to fetch all records
      request.onsuccess = () => resolve(request.result);
      request.onerror = () => reject("Failed to get posts: " + request.error);
    });
  });
}

function deletePost(postId) {
  withStore("readwrite", ({ db, transaction }) => {
    const store = transaction.objectStore("bookmarked_posts");
    store.delete(postId);
  }).catch((error) => console.error("Failed to delete post: ", error));
}

function withStore(mode, callback) {
  return new Promise((resolve, reject) => {
    openDb()
      .then((db) => {
        const transaction = db.transaction(
          ["columns", "channels", "bookmarked_posts"],
          mode
        );
        transaction.oncomplete = () => resolve();
        transaction.onerror = (event) =>
          reject("Transaction failed: " + event.target.error);

        callback({ db: db, transaction: transaction });
      })
      .catch((error) => {
        console.error("Failed to open DB: ", error);
        reject(error);
      });
  });
}

export { openDb, withStore, addPost, getPost, getAllPosts, deletePost };
