import Swal from "sweetalert2";
import { postAuth } from "../../../../globalUtils";

export const sanitizeFilename = (name) => {
  // Remove invalid file name characters regex
  return name.replace(/[/\\?%*:|"<>]/g, "-");
};

export const handleDownloadCSV = async ({
  From,
  To,
  searchInput,
  selectedLanguage,
  setCSVLoading,
}) => {
  try {
    setCSVLoading(true);
    if (From === "" || To === "") {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please fill all fields",
      });
      return;
    }

    const from = new Date(From);
    const to = new Date(To);
    if (from >= to) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "From date should be before To date",
      });
      return;
    }

    const response = await postAuth("/post/download-csv", {
      from: from.toISOString(),
      to: to.toISOString(),
      searchString: searchInput,
      Language: selectedLanguage.value,
    });

    if (response.ok) {
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = downloadUrl;

      const filename = searchInput
        ? `${sanitizeFilename(searchInput)}_posts.csv`
        : "filtered_posts.csv";
      a.download = filename;

      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(downloadUrl);
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "File has been downloaded successfully.",
      });
    } else {
      const errorData = await response.json();
      Swal.fire({
        icon: "error",
        title: "Error",
        text: errorData.message || "Failed to download file.",
      });
    }
  } catch (error) {
    Swal.fire({
      icon: "error",
      title: "Unexpected Error",
      text: "An unexpected error occurred. Please try again.",
    });
    console.error("Error during handleDownloadCSV:", error);
  } finally {
    setCSVLoading(false);
  }
};

export const handleDownload = async ({
  From,
  To,
  searchInput,
  mediaSelection,
  setMediaLoading,
}) => {
  try {
    setMediaLoading(true);

    if (From === "" || To === "") {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please fill all fields",
      });
      return;
    }

    const from = new Date(From);
    const to = new Date(To);
    if (from >= to) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "From date should be before To date",
      });
      return;
    }

    if (!mediaSelection.images && !mediaSelection.videos) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select at least one type of media",
      });
      return;
    }

    // Send to backend
    const response = await postAuth("/post/download-column-media", {
      searchString: searchInput,
      mediaSelection,
      from: from.toISOString(),
      to: to.toISOString(),
    });

    if (response.ok) {
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");

      a.href = downloadUrl;
      a.download = "media.zip";

      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(downloadUrl);

      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Media has been downloaded successfully.",
      });
    } else {
      const errorData = await response.json();
      Swal.fire({
        icon: "error",
        title: "Error",
        text: errorData.message || "Failed to download media.",
      });
    }
  } catch (error) {
    Swal.fire({
      icon: "error",
      title: "Unexpected Error",
      text: "An unexpected error occurred.",
    });
    console.error("Error during handleDownload:", error);
  } finally {
    setMediaLoading(false);
  }
};
