import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Select from "react-select";
import { DatePicker } from "../../DraggableColumn";
import { useTheme } from "../../themes/ThemeContext";
import { ToggleButton } from "./NewsMapSidebar";

const SidebarContent = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  width: 340px;
  height: calc(100vh - 40px);
  padding: 20px;
  background-color: ${({ theme }) => theme.column_background};
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  z-index: 10000000000;
  transform: translateX(${(props) => (props.$show ? "0" : "100%")});
  transition: transform 0.3s ease;
`;

export const StyledSelect = styled(Select)`
  font-size: 14px;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 20px;
`;

const SectionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InputContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const SectionTitle = styled.h4`
  color: ${({ theme }) => theme.text};
  margin-bottom: 20px;
  margin-top: 10px;
  width: 100%;
  text-align: center;
  font-size: 16px;
`;

const SearchButton = styled.button`
  background-color: #1e2836;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
`;

const StyledLabel = styled.label`
  font-size: 1em;
`;

const FiltersSidebar = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  categories,
  handleCategoryChange,
}) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [top, setTop] = useState(130);
  const [isDragging, setIsDragging] = useState(false);
  const [startY, setStartY] = useState(0);
  const { theme } = useTheme();

  const [localStartDate, setLocalStartDate] = useState(startDate);
  const [localEndDate, setLocalEndDate] = useState(endDate);
  const [localCategories, setLocalCategories] = useState([]);

  const applyFilters = () => {
    setStartDate(localStartDate);
    setEndDate(localEndDate);
    handleCategoryChange(localCategories);
    setShowSidebar(false);
  };

  const resetFilters = () => {
    setLocalStartDate(null);
    setLocalEndDate(null);
    setLocalCategories([]);
    setStartDate(null);
    setEndDate(null);
    handleCategoryChange([]);
    setShowSidebar(false);
  };

  const hasFiltersApplied = () => {
    return localStartDate || localEndDate || localCategories.length > 0;
  };

  const toggleSidebar = (e) => {
    e.stopPropagation();
    setShowSidebar((prev) => !prev);
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartY(e.clientY - top);
    e.preventDefault();
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      const newTop = e.clientY - startY;
      const windowHeight = window.innerHeight;
      const boundedTop = Math.max(0, Math.min(newTop, windowHeight - 50));
      setTop(boundedTop);
      e.preventDefault();
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (isDragging) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      if (isDragging) {
        window.removeEventListener("mousemove", handleMouseMove);
        window.removeEventListener("mouseup", handleMouseUp);
      }
    };
  }, [isDragging, startY]);

  const groupedOptions = [
    {
      label: "Conflict and Security",
      options: [
        { label: "Bombing", value: 9 },
        { label: "Ambush", value: 10 },
        { label: "Missiles", value: 11 },
        { label: "Fire Attack", value: 13 },
        { label: "Death", value: 12 },
      ],
    },
    {
      label: "Diplomacy and Society",
      options: [
        { label: "Negotiations", value: 3 },
        { label: "Politics", value: 4 },
        { label: "Statement", value: 7 },
        { label: "Visit", value: 8 },
        { label: "Protest", value: 14 },
        { label: "Arrest", value: 17 },
      ],
    },
    {
      label: "Culture and Entertainment",
      options: [
        { label: "Entertainment", value: 5 },
        { label: "Music", value: 6 },
        { label: "Sport", value: 2 },
      ],
    },
    {
      label: "Analysis",
      options: [
        { label: "Spectral Analysis", value: 15 },
        { label: "Neutral", value: 16 },
      ],
    },
  ];

  const formatGroupLabel = (data) => (
    <div style={{ fontSize: "16px", fontWeight: "bold", color: "#333" }}>
      {data.label}
    </div>
  );

  return (
    <>
      <ToggleButton
        $show={showSidebar}
        $top={top}
        onMouseDown={handleMouseDown}
        onClick={toggleSidebar}
        className="toggle-button"
      >
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "30px" }}
        >
          tune
        </span>
      </ToggleButton>
      <SidebarContent
        $show={showSidebar}
        className="sidebar-element"
        onMouseDown={(e) => e.stopPropagation()}
        onClick={(e) => e.stopPropagation()}
      >
        <h2 style={{ textAlign: "center" }}>Map Filters</h2>
        <SectionContainer>
          <SectionTitle>Choose the categories</SectionTitle>
          <StyledSelect
            isMulti
            className="toggle-button"
            value={localCategories}
            onChange={setLocalCategories}
            options={groupedOptions}
            placeholder="Select Category (Optional)"
            formatGroupLabel={formatGroupLabel}
          />

          <SectionTitle>Set Date Interval</SectionTitle>
          <InputContainer>
            <StyledLabel>Start Date</StyledLabel>
            <DatePicker
              type="datetime-local"
              className="toggle-button"
              $theme={theme}
              value={localStartDate !== null ? localStartDate : ""}
              onChange={(e) => setLocalStartDate(e.target.value)}
              placeholder="Select Start Date"
            />
          </InputContainer>
          <InputContainer>
            <StyledLabel>End Date</StyledLabel>
            <DatePicker
              type="datetime-local"
              className="toggle-button"
              $theme={theme}
              value={localEndDate !== null ? localEndDate : ""}
              onChange={(e) => setLocalEndDate(e.target.value)}
              placeholder="Select End Date"
            />
          </InputContainer>
        </SectionContainer>
        <SearchButton onClick={applyFilters}>Apply Filters</SearchButton>
        {hasFiltersApplied() && (
          <SearchButton
            onClick={resetFilters}
            style={{ backgroundColor: "red", marginTop: "10px" }}
          >
            Reset Filters
          </SearchButton>
        )}
      </SidebarContent>
    </>
  );
};

export default FiltersSidebar;
