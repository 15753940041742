import { getAuth, postAuth } from "../../../../globalUtils";

export const getMyProfile = async () => {
  try {
    const url = "/auth/profile";
    const response = await getAuth(url);

    if (!response.ok) {
      throw new Error("Failed to fetch profile from API");
    }

    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error("Error in getMyProfile:", error);
    throw error;
  }
};

export const changeMyPassword = async (oldPassword, newPassword) => {
  try {
    const url = "/auth/change-password";
    const response = await postAuth(url, { oldPassword, newPassword });

    if (!response.ok) {
      throw new Error("Failed to change password");
    }

    return response;
  } catch (error) {
    console.error("Error in changeMyPassword:", error);
    throw error;
  }
};

export const deactivateMyAccount = async () => {
  try {
    const url = "/auth/deactivate";
    const response = await postAuth(url);

    if (!response.ok) {
      throw new Error("Failed to deactivate user");
    }

    return response;
  } catch (error) {
    console.error("Error in deactivate:", error);
    throw error;
  }
};

export const updateMyProfile = async (profile) => {
  try {
    const url = "/auth/update-profile";
    const response = await postAuth(url, profile);
    const data = await response.json();

    if (!response.ok) {
      throw new Error("Failed to update profile");
    }

    return data;
  } catch (error) {
    console.error("Error in updateMyProfile:", error);
    throw error;
  }
};
