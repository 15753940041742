import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { BookmarkComponent } from "./sidepanel-components/bookmarks/Bookmarks";
import SearchComponent from "./sidepanel-components/global-search/SearchTab";

const SidePanelContainer = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  width: 400px;
  height: 100vh;
  padding: 20px;
  background-color: ${({ theme }) => theme.column_background};
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  z-index: 22;
  transform: translateX(${(props) => (props.$show ? "0" : "100%")});
  transition: transform 0.3s ease;
  max-height: 95vh;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.chip_inactive};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.background};
    border-radius: 10px;
    border: 3px solid ${({ theme }) => theme.chip_inactive};
  }
`;

const Tabs = styled.div`
  display: flex;
  border-bottom: 2px solid ${({ theme }) => theme.border || "#eaeaea"};
  overflow: hidden;
  justify-content: center;
  margin-bottom: 20px;
`;

const Tab = styled.div`
  padding: 10px 10px;
  cursor: pointer;
  display: flex;
  position: relative;
  gap: 5px;
  transition: background-color 0.3s;
  color: ${({ theme, $selected }) => ($selected ? theme.text : "#716e7d")};
  border-bottom: ${({ $selected }) =>
    $selected ? "2px solid #1e2836" : "none"};

  &:hover {
    background-color: ${({ theme }) =>
      theme.post_hover_background || "#f5f5f5"};
  }

  &::after {
    content: "";
    position: absolute;
    bottom: -3px;
    left: 0;
    right: 0;
    height: 2px;
    background-color: #1e2836;
    transform: ${({ $selected }) => ($selected ? "scaleX(1)" : "scaleX(0)")};
    transform-origin: bottom left;
    transition: transform 0.3s;
  }

  p {
    margin: 0 0 0 8px;
  }

  .material-icons {
    font-size: 24px;
  }
`;

const SidePanelTabs = ({ show }) => {
  const [showBookmarks, setShowBookmarks] = useState(true);
  const [showSearch, setShowSearch] = useState(false);
  const navigate = useNavigate();

  return (
    <SidePanelContainer $show={show}>
      <Tabs>
        <Tab
          $selected={showBookmarks}
          onClick={() => {
            setShowBookmarks(true);
            setShowSearch(false);
          }}
        >
          <span className="material-icons">bookmark</span>
          <span>Bookmarks</span>
        </Tab>
        <Tab
          $selected={showSearch}
          onClick={() => {
            setShowBookmarks(false);
            setShowSearch(true);
          }}
        >
          <span className="material-icons">search</span>
          <span>Global Search</span>
        </Tab>
        <Tab
          $selected={false}
          onClick={() => {
            navigate("/bookmark-editor");
          }}
        >
          <span className="material-icons">edit_document</span>
          <span>Editor</span>
        </Tab>
      </Tabs>
      {showBookmarks && <BookmarkComponent />}
      {showSearch && <SearchComponent />}
    </SidePanelContainer>
  );
};

export default SidePanelTabs;
