import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { useTheme } from "../themes/ThemeContext";

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const PopupContainer = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
  background-color: white; // Assuming a light background for the popup
  border-radius: 8px; // Optional for rounded corners
`;

const WidgetContainer = styled.div`
  width: 100%;
  max-height: 700px; // Adjust this to control the scrollable area height
  overflow-y: auto;
`;

function Popup({ senderusername, post_id, onClose }) {
  const widgetRef = useRef(null);
  const { theme } = useTheme();

  useEffect(() => {
    if (widgetRef.current) {
      widgetRef.current.innerHTML = "";

      const script = document.createElement("script");

      script.src = "https://telegram.org/js/telegram-widget.js?22";
      script.setAttribute("data-telegram-post", `${senderusername}/${post_id}`);
      script.setAttribute("data-width", "100%");
      script.setAttribute("data-dark", theme === "dark" ? 1 : 0);
      script.async = true;

      // Append the script to the container designated for the widget
      widgetRef.current.appendChild(script);
      const widgetcurrent = widgetRef.current;

      // Optional: Cleanup the script when the component unmounts
      return () => {
        if (widgetcurrent) {
          widgetcurrent.removeChild(script);
        }
      };
    }
  }, [senderusername, post_id, theme]);

  const handleBackgroundClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return (
    <Background onClick={handleBackgroundClick}>
      <PopupContainer>
        <WidgetContainer ref={widgetRef} />
      </PopupContainer>
    </Background>
  );
}

export default Popup;
