import React, { useEffect, useState } from "react";
import Select from "react-select";
import styled from "styled-components";
import { useCustomStyles } from "../CustomStyles";
import Notifications from "../Notifications";

const ThemedInput = styled.input.attrs({
  className: "chooseChannel CHANNELNAMEINPUTNEW",
})`
  background-color: ${({ theme }) => theme.popup_background};
  color: ${({ theme }) => theme.text};
  border-color: ${({ theme }) => theme.border_color};
`;

const ThemedIconContainer = styled.div`
  background-color: ${({ theme }) => theme.popup_background};
  color: ${({ theme }) => theme.text};
`;

export const IconSelect = ({ options, loading, onSelectIcon, ...props }) => {
  const customStyles = useCustomStyles();

  return (
    <Select
      {...props}
      options={loading ? [] : options}
      getOptionLabel={(e) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span className="material-icons" style={{ marginRight: 10 }}>
            {e.label}
          </span>
          {e.label}
        </div>
      )}
      getOptionValue={(e) => e.value}
      styles={customStyles}
      onInputChange={(inputValue) => props.onSearch(inputValue)}
      noOptionsMessage={() =>
        loading ? (
          <div className="loading-message">
            <div className="spinner"></div> Loading...
          </div>
        ) : (
          "No options"
        )
      }
      onChange={(selectedOption) => onSelectIcon(selectedOption.value)}
    />
  );
};

const FirstColumn = ({
  iconOptions,
  onSearch,
  loading,
  onIconChange,
  onNotificationChange,
  onNotificationSoundChange,
  column,
}) => {
  const [selectedIcon, setSelectedIcon] = useState("");

  const handleIconChange = (icon) => {
    setSelectedIcon(icon);
    onIconChange(icon);
  };

  return (
    <div className="RegularColumn QueryView">
      <div className="QueryAlign">
        <div>
          <h3 className="ColumnHeader">Column Name</h3>
          <ThemedInput
            className="ColumnNameInput TextArea"
            placeholder="Column Name"
          />
        </div>
        <div>
          <h3>Icon</h3>
          <ThemedIconContainer className="IconContainer">
            <IconSelect
              className="IconSelect"
              options={iconOptions}
              onSearch={onSearch}
              loading={loading}
              onSelectIcon={handleIconChange}
            />
          </ThemedIconContainer>
        </div>
        <Notifications
          onNotificationChange={onNotificationChange}
          onNotificationSoundChange={onNotificationSoundChange}
          column={column}
        />
      </div>
    </div>
  );
};

export default FirstColumn;
