import React, { useState } from "react";
import styled from "styled-components";
import { postAuth } from "../../../../../globalUtils";
import Swal from "sweetalert2";

// Styled components
const Section = styled.section`
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 0 auto;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormGroup = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
  outline: none;

  &:focus {
    border-color: #007bff;
  }
`;

const Button = styled.button`
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

const CreateOrganizationForm = () => {
  const [organizationName, setOrganizationName] = useState("");
  const [managerEmail, setManagerEmail] = useState("");
  const [maxCapacity, setMaxCapacity] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await postAuth(`/organization/create`, {
        name: organizationName,
        email: managerEmail,
        max_capacity: maxCapacity,
      });
      const responseData = await response.json();
      if (responseData.status === "success") {
        Swal.fire({
          title: "Organization Created",
          text: "The organization has been created successfully.",
          icon: "success",
        });
      } else {
        Swal.fire("Error", responseData.message, "error");
      }
    } catch (error) {
      console.error("Failed to create organization:", error);
      Swal.fire("Error", "Failed to create organization", "error");
    }
  };

  return (
    <Section>
      <h1
        style={{
          textAlign: "center",
          marginBottom: "20px",
        }}
      >
        Create Organization
      </h1>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label htmlFor="organizationName">Organization Name:</Label>
          <Input
            type="text"
            id="organizationName"
            value={organizationName}
            onChange={(e) => setOrganizationName(e.target.value)}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="managerEmail">
            Organization Manager Email (case-sensitive):
          </Label>
          <Input
            type="email"
            id="managerEmail"
            value={managerEmail}
            onChange={(e) => setManagerEmail(e.target.value)}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="maxCapacity">Max Capacity:</Label>
          <Input
            type="number"
            id="maxCapacity"
            value={maxCapacity}
            onChange={(e) => setMaxCapacity(e.target.value)}
            required
            min="1"
          />
        </FormGroup>
        <Button type="submit">Create Organization</Button>
      </Form>
    </Section>
  );
};

export default CreateOrganizationForm;
