import { postAuth } from '../../globalUtils';

export const fetchChannelsWithTags = async (query, tags = [], chosenChannelIds = []) => {
    try {
        // Include chosenChannelIds in the request payload
        const response = await postAuth('/channel/searchWithTags', {
            search: query,
            tags,
            exclude: chosenChannelIds  // Assuming the backend knows how to handle 'exclude'
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching channels with tags:', error);
        return null;
    }
};