import AreaTable from "../components/dashboard/areaTable/Channels/AreaTable";

const Channels = () => {
  return (
    <div className="content-area">
      <AreaTable />
    </div>
  );
};
export default Channels;
