import { getAuth } from '../../globalUtils';

export const fetchIcons = async (query = '') => {
    try {
        const response = await getAuth(`/icon/${query}`);
        const data = await response.json();
        return data.data.map(icon => ({
            id: icon.id,
            value: icon.name,
            label: icon.name
        }));
    } catch (error) {
        console.error('Error fetching icons:', error);
        return [];
    }
};
