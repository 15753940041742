import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { getAuth, deleteAuth } from "../../../../../globalUtils";
import styled from "styled-components";
import SearchBar from "./SearchBar";

const PaginationControls = styled.div`
  display: flex;
  justify-content: center;
  align-items: center; /* Center align items vertically */
  margin-top: 20px;

  & > button {
    margin: 0 5px;
  }

  .page-info {
    margin: 0 10px;
    font-weight: bold;
  }
`;

const Table = () => {
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Adjust this value for the number of items per page
  const [filteredData, setFilteredData] = useState([]); // To store filtered data
  const [searchTerm, setSearchTerm] = useState(""); // State to hold search term

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await getAuth(
        `/admin_user/?page=${currentPage}&limit=${itemsPerPage}`
      );
      const responseData = await response.json();
      setFilteredData(responseData.data); // Initially set filtered data to be the same as fetched data
      setCount(responseData.total);
    } catch (error) {
      console.error("Failed to fetch users:", error);
      setFilteredData([]); // Clear filtered data if fetch fails
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  useEffect(() => {
    const fetchFilteredData = async () => {
      if (searchTerm.trim() === "") {
        fetchData(); // If no search term, show all data
        return;
      }

      setLoading(true);
      try {
        const response = await getAuth(
          `/admin_user/search/?email=${encodeURIComponent(searchTerm)}`
        );
        const responseData = await response.json();
        setFilteredData(responseData.data);
        setCount(responseData.total); // Adjust total count for pagination
      } catch (error) {
        console.error("Failed to fetch filtered users:", error);
        setFilteredData([]); // Clear the data if there's an error
      } finally {
        setLoading(false);
      }
    };

    fetchFilteredData();
  }, [searchTerm]);

  const deleteUser = async (id) => {
    try {
      const response = await deleteAuth(`/admin_user/${id}`);
      await response.json();
      if (response.ok) {
        alert("User deleted successfully");
        const updatedUsers = filteredData.filter((user) => user.id !== id);
        setFilteredData(updatedUsers);
      }
    } catch (error) {
      console.error("Failed to delete user:", error);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const totalPages = Math.ceil(count / itemsPerPage);

  function formatDate(apiDate) {
    const date = new Date(apiDate);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return date.toLocaleDateString(undefined, options);
  }

  const handleDownloadCSV = () => {
    const csvContent = [
      [
        "First Name",
        "Last Name",
        "Email",
        "Phone Number",
        "Role",
        "Verification",
        "Creation Date",
      ],
      ...filteredData.map((user) => [
        user.first_name || "N/A",
        user.last_name || "N/A",
        user.email,
        user.phone || "N/A",
        user.role || "N/A",
        user.isVerified ? "Verified" : "Not Verified",
        formatDate(user.created_at) || "N/A",
      ]),
    ]
      .map((e) => e.join(","))
      .join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "users_data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <SearchBar
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        handleDownloadCSV={handleDownloadCSV}
      />
      <p style={{ marginBottom: "20px" }}>Number Of Users: {count}</p>
      <div className="data-table-diagram">
        {loading ? (
          <p>Loading...</p>
        ) : (
          <>
            <table>
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                  <th>Role</th>
                  <th>Organization</th>
                  <th>Verification</th>
                  <th>Total Time Spent (hours)</th>
                  <th>Creation Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.length > 0 ? (
                  filteredData.map((item) => (
                    <tr key={item.id}>
                      <td>{item.first_name || "N/A"}</td>
                      <td>{item.last_name || "N/A"}</td>
                      <td>{item.email}</td>
                      <td>{item.phone || "N/A"}</td>
                      <td>{item.role || "N/A"}</td>
                      <td>{item.organizationName || "N/A"}</td>
                      <td>{item.isVerified ? "Verified" : "Not Verified"}</td>
                      <td>{item.total_time_spent || "N/A"}</td>
                      <td>
                        {item.created_at ? formatDate(item.created_at) : "N/A"}
                      </td>
                      <td>
                        <Button
                          onClick={() => {
                            if (
                              window.confirm(
                                "Are you sure you want to delete this user?"
                              )
                            ) {
                              deleteUser(item.id);
                            }
                          }}
                          variant="contained"
                          sx={{
                            backgroundColor: "red",
                            color: "#fff",
                            "&:hover": {
                              backgroundColor: "#cc0000",
                            },
                          }}
                          startIcon={<DeleteIcon />}
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8">No users found</td>
                  </tr>
                )}
              </tbody>
            </table>
            <PaginationControls>
              <Button
                variant="contained"
                onClick={() => handlePageChange(1)}
                disabled={currentPage === 1}
              >
                First
              </Button>
              <Button
                variant="contained"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </Button>
              <span className="page-info">
                Page {currentPage} of {totalPages}
              </span>
              <Button
                variant="contained"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </Button>
              <Button
                variant="contained"
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages}
              >
                Last
              </Button>
            </PaginationControls>
          </>
        )}
      </div>
    </>
  );
};

export default Table;
