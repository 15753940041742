import AreaTable from "../components/dashboard/areaTable/verify-user/AreaTable";

const Assign = () => {
  return (
    <div className="content-area">
      <AreaTable />
    </div>
  );
};
export default Assign;
