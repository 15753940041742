import { useEffect, useState } from "react";
import { getAuth } from "../../../../globalUtils";

const AreaProgressChart = () => {
  const [serviceData, setServiceData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [maxCount, setMaxCount] = useState(0); // To store the maximum count

  useEffect(() => {
    const fetchCategoryData = async () => {
      try {
        // Fetch data from the API
        const response = await getAuth("/admin_user/posts-per-category");
        const data = await response.json();

        if (data.status === "success") {
          const formattedData = data.data.map((category) => ({
            id: category.id,
            name: category.name,
            count: category.count,
          }));

          // Find the maximum count value
          const max = Math.max(
            ...formattedData.map((category) => category.count)
          );
          setMaxCount(max); // Set the max count for scaling

          setServiceData(formattedData);
        }
      } catch (error) {
        console.error("Error fetching category data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCategoryData();
  }, []);

  return (
    <div className="progress-bar">
      <div className="progress-bar-info">
        <h4 className="progress-bar-title">Number of Posts by Category</h4>
      </div>
      {isLoading ? (
        <p>Loading service data...</p>
      ) : (
        <div className="progress-bar-list">
          {serviceData.map((progressbar) => (
            <div className="progress-bar-item" key={progressbar.id}>
              <div className="bar-item-info">
                <p className="bar-item-info-name">{progressbar.name}</p>
                <p className="bar-item-info-value">{progressbar.count} posts</p>
              </div>
              <div className="bar-item-full">
                <div
                  className="bar-item-filled"
                  style={{
                    width: `${(progressbar.count / maxCount) * 50}%`,
                  }}
                ></div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AreaProgressChart;
