import { Routes, Route, Navigate } from "react-router-dom";
import Users from "./screens/Users";
import Dashboard from "./screens/Dashboard";
import PageNotFound from "./screens/PageNotFound";
import BaseLayout from "./layout/BaseLayout";
import Orgs from "./screens/Organizations";
import Channels from "./screens/Channels";
import Tags from "./screens/Tags";
import Assign from "./screens/AssignUser";
import ChannelRequests from "./screens/ChannelRequests";
import AddOrg from "./screens/AddOrg";
import BusinessUsers from "./screens/BusinessUsers";
import { useAuth } from "../components/authentication/AuthContext/AuthContext";

import "./App.scss";

const AdminRoutes = () => {
  const { isAuthenticated, role } = useAuth();

  if (!isAuthenticated || role === "user") {
    return <Navigate to="/" replace />;
  }

  return (
    <div className="admin">
      <Routes>
        <Route element={<BaseLayout />}>
          {/* Only Admins can access all the routes */}
          {role === "admin" && (
            <>
              <Route path="/" element={<Dashboard />} />
              <Route path="/users" element={<Users />} />
              <Route path="/organizations" element={<Orgs />} />
              <Route path="/channels" element={<Channels />} />
              <Route path="/tags" element={<Tags />} />
              <Route path="/assign" element={<Assign />} />
              <Route path="/channel-requests" element={<ChannelRequests />} />
              <Route path="/add-organization" element={<AddOrg />} />
            </>
          )}

          {/* Business Users can only access Business Users route */}
          {role === "business" && (
            <Route path="/business-users" element={<BusinessUsers />} />
          )}

          {/* Page Not Found for unknown paths */}
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </div>
  );
};

export default AdminRoutes;
