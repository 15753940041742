import React, { useEffect, useState } from "react";
import { Button as MuiButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { getAuth, deleteAuth, postAuth } from "../../../../../globalUtils";
import styled from "styled-components";
import SearchBar from "./SearchBar";
import ViewTeamPopup from "./ViewTagPopup";

const PaginationControls = styled.div`
  display: flex;
  justify-content: center;
  align-items: center; /* Center align items vertically */
  margin-top: 20px;

  & > button {
    margin: 0 5px;
  }

  .page-info {
    margin: 0 10px;
    font-weight: bold;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column; /* Stack buttons vertically */
  gap: 10px; /* Space between buttons */
`;

const Table = () => {
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Adjust this value for the number of items per page
  const [filteredData, setFilteredData] = useState([]); // To store filtered data
  const [searchTerm, setSearchTerm] = useState(""); // State to hold search term
  const [viewTagsModal, setViewTagsModal] = useState(false);
  const [tags, setTags] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await getAuth(
        `/channel/?page=${currentPage}&limit=${itemsPerPage}`
      );
      const responseData = await response.json();
      setFilteredData(responseData.data); // Initially set filtered data to be the same as fetched data
      setCount(responseData.total);
    } catch (error) {
      console.error("Failed to fetch users:", error);
      setFilteredData([]); // Clear filtered data if fetch fails
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  useEffect(() => {
    const fetchFilteredData = () => {
      if (searchTerm.trim() === "") {
        fetchData(); // If no search term, show all data
        return;
      }

      setLoading(true);
      try {
        const filtered = filteredData.filter((org) =>
          org.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredData(filtered);
        setCount(filtered.length); // Adjust total count for pagination
      } catch (error) {
        console.error("Failed to filter users:", error);
        setFilteredData([]); // Clear the data if there's an error
      } finally {
        setLoading(false);
      }
    };

    fetchFilteredData();
  }, [searchTerm]);


  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const totalPages = Math.ceil(count / itemsPerPage);

  const handleDownloadCSV = () => {
    const csvContent = [
      ["Username", "Channel Title", "Source URL"],
      ...filteredData.map((item) => [
        item.sender_username || "N/A",
        item.chat_title || "N/A",
        item.source_url || "N/A",
      ]),
    ]
      .map((e) => e.join(","))
      .join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "channels_data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getTags = async (id) => {
    try {
      const response = await getAuth(`/tag/${id}`);
      const responseData = await response.json();
      setTags(responseData.data);
      setViewTagsModal(true);
    } catch (error) {
      console.error("Failed to fetch team members:", error);
    }
  };

  const onCloseViewTeamModal = () => {
    setViewTagsModal(false);
  };

  return (
    <>
      <ViewTeamPopup
        open={viewTagsModal}
        onClose={onCloseViewTeamModal}
        tags={tags}
      />
      <SearchBar
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        handleDownloadCSV={handleDownloadCSV}
      />
      <p style={{ marginBottom: "20px" }}>Number Of Channels: {count}</p>
      <div className="data-table-diagram">
        {loading ? (
          <p>Loading...</p>
        ) : (
          <>
            <table>
              <thead>
                <tr>
                  <th>Channel Username</th>
                  <th>Channel Title</th>
                  <th>Source URL</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.length > 0 ? (
                  filteredData.map((item) => (
                    <tr key={item.id}>
                      <td>{item.sender_username || "N/A"}</td>
                      <td>{item.chat_title || "N/A"}</td>
                      <td>{item.source_url}</td>
                      <td>
                        <ButtonContainer>
                          <MuiButton
                            onClick={() => {
                              getTags(item.id);
                            }}
                            variant="contained"
                            sx={{
                              backgroundColor: "#007bff", // Blue color for "View Team"
                              color: "#fff",
                              "&:hover": {
                                backgroundColor: "#0056b3", // Darker blue on hover
                              },
                            }}
                          >
                            View Tags
                          </MuiButton>
                        </ButtonContainer>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8">No users found</td>
                  </tr>
                )}
              </tbody>
            </table>
            <PaginationControls>
              <MuiButton
                variant="contained"
                onClick={() => handlePageChange(1)}
                disabled={currentPage === 1}
              >
                First
              </MuiButton>
              <MuiButton
                variant="contained"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </MuiButton>
              <span className="page-info">
                Page {currentPage} of {totalPages}
              </span>
              <MuiButton
                variant="contained"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </MuiButton>
              <MuiButton
                variant="contained"
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages}
              >
                Last
              </MuiButton>
            </PaginationControls>
          </>
        )}
      </div>
    </>
  );
};

export default Table;
