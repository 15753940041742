import { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { FaArrowUpLong } from "react-icons/fa6";
import "./AreaCharts.scss";
import { getAuth } from "../../../../globalUtils";

const AreaBarChart = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [valueChange, setValueChange] = useState("");

  useEffect(() => {
    const fetchChartData = async () => {
      setIsLoading(true);
      try {
        const response = await getAuth("/admin_user/monthly-post-count");
        const data = await response.json();

        if (data.status === "success") {
          // Map API data to chart format
          const formattedData = data.data.map((item) => ({
            month: item.month,
            news: item.count, // Assuming "news" key is used in the chart
          }));

          setChartData(formattedData);

          // Optionally, calculate value change here based on the data
          if (formattedData.length > 1) {
            const lastMonth = formattedData[formattedData.length - 1].news;
            const previousMonth = formattedData[formattedData.length - 2].news;
            const change = ((lastMonth - previousMonth) / previousMonth) * 100;
            setValueChange(change.toFixed(2)); // Display as percentage
          } else {
            setValueChange(0);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchChartData();
  }, []);

  const formatTooltipValue = (value) => {
    return `${value}`;
  };

  const formatYAxisLabel = (value) => {
    return `${value}`;
  };

  const formatLegendValue = (value) => {
    return value.charAt(0).toUpperCase() + value.slice(1);
  };

  return (
    <div className="bar-chart">
      <div className="bar-chart-info">
        <h5 className="bar-chart-title">News Posts Monitoring Statistics</h5>
        <div className="chart-info-data">
          <div className="info-data-text">
            <FaArrowUpLong />
            <p>
              {" "}
              {valueChange > 0
                ? `${valueChange}% increase from last month`
                : valueChange < 0
                ? `${valueChange}% decrease from last month`
                : "No change from last month"}{" "}
            </p>
          </div>
        </div>
      </div>
      <div className="bar-chart-wrapper">
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={200}
              data={chartData}
              margin={{
                top: 5,
                right: 5,
                left: 0,
                bottom: 5,
              }}
            >
              <XAxis
                padding={{ left: 10 }}
                dataKey="month"
                tickSize={0}
                axisLine={false}
                tick={{
                  fill: "#676767",
                  fontSize: 16,
                }}
                tickFormatter={(month) => month.slice(0, 3)}
              />

              <YAxis
                padding={{ bottom: 10, top: 10 }}
                tickFormatter={formatYAxisLabel}
                tickCount={6}
                axisLine={false}
                tickSize={0}
                tick={{
                  fill: "#676767",
                }}
              />
              <Tooltip
                formatter={formatTooltipValue}
                cursor={{ fill: "transparent" }}
              />
              <Legend
                iconType="circle"
                iconSize={10}
                verticalAlign="top"
                align="right"
                formatter={formatLegendValue}
              />
              <Bar
                dataKey="news"
                fill="#475be8"
                activeBar={false}
                isAnimationActive={false}
                barSize={24}
                radius={[4, 4, 4, 4]}
              />
            </BarChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
};

export default AreaBarChart;
