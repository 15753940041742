import React, { createContext, useContext, useState, useEffect } from "react";
import { getAllPosts } from "../../../localdb/DBUtils";

const BookmarkContext = createContext();

export const BookmarkProvider = ({ children }) => {
  const [bookmarkedPosts, setBookmarkedPosts] = useState([]);

  useEffect(() => {
    getAllPosts()
      .then((posts) => {
        setBookmarkedPosts(posts);
      })
      .catch((error) => {
        console.error("Error fetching bookmarked posts:", error);
      });
  }, [bookmarkedPosts]);

  const addPostToContext = (post) => {
    var x = setBookmarkedPosts((prevPosts) => {
      // Check if the post is already in the array
      if (prevPosts.some((p) => p.post_id === post.post_id)) {
        return prevPosts; // Return the previous posts unchanged
      } else {
        return [...prevPosts, post]; // Add new post to the list
      }
    });
  };

  const deleteContextPost = (postId, main_id) => {
    var element = document.getElementById(main_id);
    var className = "click-" + postId;
    if (element) {
      if (element.textContent.trim() === "bookmark") element.click();
    }

    var elements = document.getElementsByClassName(className);

    for (var i = 0; i < elements.length; i++) {
      var element = elements[i];
      if (element.textContent.trim() === "bookmark") {
        element.textContent = "bookmark_border";
      }
    }

    setBookmarkedPosts((prevPosts) =>
      prevPosts.filter((post) => post.post_id !== postId)
    );
  };

  return (
    <BookmarkContext.Provider
      value={{ bookmarkedPosts, addPostToContext, deleteContextPost }}
    >
      {children}
    </BookmarkContext.Provider>
  );
};

export const useBookmarks = () => {
  return useContext(BookmarkContext);
};
