import React, { createContext, useContext, useState, useEffect } from "react";
import { postAuth } from "../../../globalUtils";

const AuthContext = createContext({
  isAuthenticated: false,
  isVerified: false,
  isLoading: true,
  tokenAttemptedRefresh: false,
  setAuth: () => {},
});
export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    isAuthenticated: false,
    isVerified: false,
    role: "user",
    isLoading: true,
    userId: "",
    tokenAttemptedRefresh: false,
  });

  const updateAuth = (newAuth) => {
    setAuth((prev) => ({ ...prev, ...newAuth }));
  };

  const handleAuthResponse = (response, data) => {
    if (response.ok) {
      switch (data.status) {
        case "unverified":
          updateAuth({
            isAuthenticated: false,
            isVerified: false,
            isLoading: false,
          });
          break;
        case "success":
          updateAuth({
            isAuthenticated: true,
            isVerified: true,
            isLoading: false,
            role: data.role,
            userId: data.userId,
          });
          break;
        case "completed":
          updateAuth({
            isAuthenticated: true,
            isVerified: data.isVerified === 1,
            isLoading: false,
            tokenAttemptedRefresh: false, // Reset on successful refresh
            role: data.role,
            userId: data.userId,
          });
          break;
        default:
          return false;
      }
      return true;
    } else {
      if (!auth.tokenAttemptedRefresh) {
        updateAuth({ tokenAttemptedRefresh: true }); // Prevent infinite loop
      }
      return false;
    }
  };

  const verifyToken = async () => {
    try {
      const response = await postAuth("/auth/validate-token", {});
      const data = await response.json();
      if (!handleAuthResponse(response, data)) {
        updateAuth({ isAuthenticated: false, isLoading: false });
      }
    } catch (error) {
      console.error("Request failed, Error during token validation:", error);
      updateAuth({ isAuthenticated: false, isLoading: false });
    }
  };

  useEffect(() => {
    verifyToken();
  }, []);

  return (
    <AuthContext.Provider value={{ ...auth, setAuth: updateAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
