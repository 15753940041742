import React, { useState } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import { useTheme } from "../../themes/ThemeContext"; 
import { ContentRow } from "./ContentRow"; 

export default function MenuSimple() {
  const [anchorEl, setAnchorEl] = useState(null);
  const { theme, toggleTheme } = useTheme();

  const handleChange = (newTheme) => {
    toggleTheme(newTheme);
  };
  const handleOpen = (event) => {
    event.stopPropagation();

    setAnchorEl(event.currentTarget);
  };

  return (
    <ContentRow title="Theme">
      <Button
        aria-controls="theme-menu"
        aria-haspopup="true"
        onClick={handleOpen}
        sx={{
          height: "39px",
          width: "68px",
          fontWeight: "inherit",
          fontSize: "14px",
          backgroundColor: "#007bff", // Set your desired background color here
          color: "white", // Set text color
          "&:hover": {
            backgroundColor: "#002379", // Darker background on hover
          },
          borderRadius: "4px", // Rounded corners
          textTransform: "none", // No uppercase
        }}
      >
        {theme.charAt(0).toUpperCase() + theme.slice(1)}
      </Button>

      <Menu
        id="theme-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClick={(event) => event.stopPropagation()} // Stop propagation when menu is clicked
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          sx: {
            backgroundColor: theme === "dark" ? "#333" : "#fff",
            color: theme === "dark" ? "#fff" : "#000",
          },
        }}
      >
        <MenuItem
          onClick={() => handleChange("light")}
          sx={{
            backgroundColor: theme === "dark" ? "#333" : "#fff",
            color: theme === "dark" ? "#fff" : "#000",
            "&:hover": {
              backgroundColor: theme === "dark" ? "#555" : "#ddd",
            },
          }}
        >
          Light
        </MenuItem>
        <MenuItem
          onClick={() => handleChange("dark")}
          sx={{
            backgroundColor: theme === "dark" ? "#333" : "#fff",
            color: theme === "dark" ? "#fff" : "#000",
            "&:hover": {
              backgroundColor: theme === "dark" ? "#555" : "#ddd",
            },
          }}
        >
          Dark
        </MenuItem>
      </Menu>
    </ContentRow>
  );
}
